import HTTPClient from "../http-common";
import API_KEYS from "./apiKeys";

class AdminDataService {
  
  getGodCategories(lang) {
    return HTTPClient.getHttp().get(`/godcategories/${lang}`,{
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.SERVICE_API_KEY
      }
    });
  }

  getDonationsList(lr) {
    return HTTPClient.getAuthHttp().get(`/donations/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getMyServicesList(lr) {
    return HTTPClient.getAuthHttp().get(`/servicereg/user/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getDonationsByMonth(year, month) {
    return HTTPClient.getAuthHttp().get(`/reports/yr/${year}/month/${month}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getDonationsDetails(lr) {
    return HTTPClient.getAuthHttp().get(`/donations/details/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getExpensesList(lr) {
    return HTTPClient.getAuthHttp().get(`/expenses/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleServiceBookingList(lr) {
    return HTTPClient.getAuthHttp().get(`/servicereg/pos/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getExpensesDetails(lr) {
    return HTTPClient.getAuthHttp().get(`/expenses/details/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postUserActivity(data) {
    return HTTPClient.getHttp().post(`/user/activity`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.USER_API_KEY
      }
    });
  }


  createDonations(data) {
    return HTTPClient.getAuthHttp().post("/donations", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  createExpenses(data) {
    return HTTPClient.getAuthHttp().post("/expenses", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateDonationType(id, data) {
    return HTTPClient.getAuthHttp().put(`/donations/${id}/donationtype`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateDonorDetais(id, data) {
    return HTTPClient.getAuthHttp().put(`/donations/${id}/donordetails`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateDonationPaymentDetais(id, data) {
    return HTTPClient.getAuthHttp().put(`/donations/${id}/paymentdetails`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleDashboard() {
    return HTTPClient.getAuthHttp().get(`/temple/dashboard`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleMenbers() {
    return HTTPClient.getAuthHttp().get(`/temple/users`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getPendingTemples(lang, lr) {
    return HTTPClient.getAuthHttp().get(`/temple/${lang}/pending/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }


  postAddUserToTemple(uid, data) {
    return HTTPClient.getAuthHttp().post(`/temple/user/${uid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postAddTempleAdmin(tid, uid, data) {
    return HTTPClient.getAuthHttp().put(`/temple/${tid}/user/${uid}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleUsersByStCity(st, city, lr, data) {
    return HTTPClient.getAuthHttp().get(`/temple/st/${st}/city/${city}/lr/${lr}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getAppUsers(lr) {
    return HTTPClient.getAuthHttp().get(`/user/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleComplaints(lr) {
    return HTTPClient.getAuthHttp().get(`/temple/complaints/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteTempleUser(uid) {
    return HTTPClient.getAuthHttp().delete(`/temple/user/${uid}/remove`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateExpenseType(id, data) {
    return HTTPClient.getAuthHttp().put(`/expenses/${id}/expensetype`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateDonationAmount(id, data) {
    return HTTPClient.getAuthHttp().put(`/donations/${id}/amount`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateExpenseAmount(id, data) {
    return HTTPClient.getAuthHttp().put(`/expenses/${id}/amount`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateDonationNotes(id, data) {
    return HTTPClient.getAuthHttp().put(`/donations/${id}/notes`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateExpenseNotes(id, data) {
    return HTTPClient.getAuthHttp().put(`/expenses/${id}/notes`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateDonationPayment(id, data) {
    return HTTPClient.getAuthHttp().put(`/donations/${id}/payment`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateDonationBill(id, data) {
    return HTTPClient.getAuthHttp().put(`/donations/${id}/bill`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateExpenseBill(id, data) {
    return HTTPClient.getAuthHttp().put(`/expenses/${id}/bill`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateUserRole(id, data) {
    return HTTPClient.getAuthHttp().put(`/user/role/${id}`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteDonation(did, ts) {
    return HTTPClient.getAuthHttp().delete(`donation/${did}/ts/${ts}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  deleteExpenses(did, ts) {
    return HTTPClient.getAuthHttp().delete(`expenses/${did}/ts/${ts}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  createTranslation(ini, tid, target) {
    return HTTPClient.getAuthHttp().post(`/temple/${ini}/tid/${tid}/lang/${target}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }
  
  delete(id) {
    return HTTPClient.getAuthHttp().delete(`/temple/${id}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

}

export default new AdminDataService();