import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { editResource, fetchNotificationDetails, fetchRegionalNotifi, fetchResource, fetchResourceDetails, postResource, postService, postTemples } from '../../../../redux/actions/acions';

import ImgCrop from 'antd-img-crop';
import ReactCrop from 'react-image-crop';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import { getNotificationDetailsList, getRegionalNotifiList, getResourceDetailsList, getResourceList } from '../../../../redux/selectors/selectors';
import i18next from 'i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import AP_LANGUAGES from "../../../common/appLangs"

const normFile = (e) => {

    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const CreateService = () => {
    const { TextArea } = Input;
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const { nid } = useParams()

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const phoneRegex = /^[0-9]+$/;

    const onFinish = () => {
        const values = form.getFieldsValue();
        const data = {
            "tid": values.tid,
            "title": values.title,
            "ctgry": values.ctgry,
            "servtype": values.servtype,
            "bookbefore": values.bookbefore,
            "amount": values.amount,
            "capacity": values.capacity,
            "active": true,
            "notes": values.notes
        }
        dispatch(postService(data, success, failure))
        navigate('/adminService');
    };

    const storedLanguage = localStorage.getItem('lng') || 5;
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    const handleChange = (value) => {
        localStorage.setItem('lng', value);
        setSelectedLanguage(value);
        // Make API request here with the selected language
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const [showAllImages, setShowAllImages] = useState(false);

    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (did) => {
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_newservice_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SERVICE CREATED SUCCESSFULLY',
        // });
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_newservice_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,
        // });
    };
    const { tDetails } = useSelector(state => ({
        tDetails: getNotificationDetailsList(state),
    }))


    useEffect(() => {
        dispatch(fetchNotificationDetails(nid, storedLanguage));
    }, [storedLanguage, dispatch]);


    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails])

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const handleCancelClick = () => {
        // Reset the form to its initial values
        form.resetFields();
        navigate(-1)
        // setButtonText('Edit');
        // setEditable(false);
    };

    const servicesData = {
        Darshan: {
            dar: ['Free Darshan', 'Special Darshan', 'VIP Darshan'],
        },
        Puja: {
            special_pujas: [
                'Sri Satyanarayana Vratam (11AM Monday to Friday)',
                'Nitya Seva',
                'Sri Venkateswara Archana',
                'Sri Lakshmi Archana',
                'Sri Srinivasa Kalyanam',
                'Sri Andal Archana',
                'Sri Anjaneya Archana',
                'Sri Ganesha Archana',
                'Ekantha Seva',
                'Sri Venkateswara Sahasranama Archana',
                'Sri Lakshmi Sahasranama Archana'
            ],
            daily_pujas: ['Daily Gothra Puja', 'Monthly Gothra Puja', 'Abhishekam'],
        },
        Decoration: {
            flowers: ['Flowers'],
            gandham: ['Gandham'],
        },
        Seva: {
            kalyanam: ['Kalyanam'],
            '108_nama_japam': ['108 Nama Japam'],
        },
        Utsav: {
            pallaki_seva: ['Pallaki Seva'],
            grama_utshav: ['Grama Utshav'],
        },
        Accommodation: {
            non_ac_room: ['NON-AC Room'],
            ac_room: ['AC Room'],
            non_ac_dormitory: ['NON-AC Dormitory'],
            ac_dormitory: ['AC Dormitory'],
        },
    };

    return (
        <div class='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_create_service')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-link">{t("label_create_service")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt">{t('label_create_service')} </div>
                    <div class="details-headertxt-singleLine-btns">
                        <a   class="button_move" onClick={handleCancelClick} style={{ marginRight: '5px' }}>
                            {t("label_cancel")}
                        </a>
                        <a   class="button_move" ype="primary" htmlType="submit" onClick={onFinish}>
                            {t('label_save')}
                        </a>
                    </div>
                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    <Form form={form} name="nest-messages" onFinish={onFinish}
                        initialValues={tDetails}
                    // labelCol={{
                    //     span: 6,
                    //   }}
                    //   wrapperCol={{
                    //     span: 8,
                    //   }}
                    >
                        <div class="float-left w-100" ><h5 class="card-text-title">{t("label_service_details")} <span>| {t('label_ctgry_srvc_amt')}</span></h5></div>
                        {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
                        <div class="active-details">



                            {/* <div class="tiles-designs" >
                                <Form.Item
                                    name='tid'
                                    label={<Typography.Title level={4}>{t('Temple ID')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('Enter Temple ID')} />
                                </Form.Item>
                            </div> */}



                            {/* <div class="container" ><h5 class="card-text-title">{t('Additional Details')} <span>| Category ,Service Type, Capacity, etc</span></h5></div> */}
                            {/* <div class="active-details"> */}
                            {/* <Col span={24}> */}
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='title'
                                    label={<Typography.Title level={4}>{t('label_title')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('label_enter_title')} />
                                </Form.Item>
                            </div>
                            <div className="tiles-designs">
                                <Form.Item
                                    name='ctgry'
                                    //style={{ width: '30%' }}
                                    label={<Typography.Title level={4}>{t('label_categ')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select category"
                                        onChange={handleCategoryChange}
                                    >
                                        {Object.keys(servicesData).map((category) => (
                                            <Option key={category} value={category}>
                                                {category}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="tiles-designs">
                                <Form.Item
                                    name='servtype'
                                    //style={{ width: '30%' }}
                                    label={<Typography.Title level={4}>{t('label_service_type')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Type"
                                        disabled={!selectedCategory}
                                    >
                                        {selectedCategory &&
                                            Object.keys(servicesData[selectedCategory]).map((subCategory) =>
                                                servicesData[selectedCategory][subCategory].map((serviceType) => (
                                                    <Option key={serviceType} value={serviceType}>
                                                        {serviceType}
                                                    </Option>
                                                ))
                                            )}
                                    </Select>
                                </Form.Item>
                            </div>
                            {/* <div class="tiles-designs" >
                                <Form.Item
                                    name='dondt'
                                    label={<Typography.Title level={4}>{t('Date')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        style={{ height: '30px' }}
                                    />
                                </Form.Item>
                            </div> */}
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='bookbefore'
                                    label={<Typography.Title level={4}>{t('label_bookbefore')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: phoneRegex,
                                            message: 'Please enter a valid Number! Only numbers are allowed.',
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('label_enter_bookbefore_days')} />
                                </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='amount'
                                    label={<Typography.Title level={4}>{t('label_amount')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: phoneRegex,
                                            message: 'Please enter a valid Number! Only numbers are allowed.',
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('label_enter_amount')} />
                                </Form.Item>
                            </div>
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='capacity'
                                    label={<Typography.Title level={4}>{t('label_capacity')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            pattern: phoneRegex,
                                            message: 'Please enter a valid Number! Only numbers are allowed.',
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('label_enter_capacity')} />
                                </Form.Item>
                            </div>
                            {/* <div class="tiles-designs" >
                                <Form.Item
                                    name='active'
                                    label={<Typography.Title level={4}>{t('Active')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('Enter source link')} />
                                </Form.Item>
                            </div> */}
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='notes'
                                    label={<Typography.Title level={4}>{t('label_notes')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} placeholder={t('label_enter_details')} />
                                </Form.Item>
                            </div>
                            {/* <div class="tiles-designs" >
                                <Form.Item
                                    name="lang"
                                    label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                    initialValue={selectedLanguage}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a language.',
                                        },
                                    ]}
                                >
                                    <Select options={AP_LANGUAGES} placeholder={t('label_select_language')} />
                                </Form.Item>
                            </div> */}


                        </div>



                        {/* <div class="active-details">


                            <button type="submit" class="button_move">{t("label_save")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button>
                        </div> */}

                    </Form>
                </div>
                <Footer />
            </div>

        </div>

    );
};
export default CreateService;