import React, { useEffect, useState } from 'react'

import { Breadcrumb, Button, Modal, Select, Skeleton, Typography, message } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import remedyTemple from '../../../../assets/images/remedy-temple.jpg'
import famousTemples from '../../../../assets/images/famous-temple.jpg'
import cholaTemples from '../../../../assets/images/chola.jpg'
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';
import Images from '../../../common/images';
import showToast from '../../../../utils/showToast';
import defImage from '../../../../assets/images/templeicon.jpg'
import AP_LANGUAGES from "../../../common/appLangs"
import Error from '../error';

const TempleCtgry = () => {
    const { t } = useTranslation();
    const [dialogVisible, setDialogVisible] = useState(false); // State to control modal visibility
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const storedLanguage = localStorage.getItem('lng') || 5;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])
    const handleChange = (value) => {
        setSelectedLanguage(value);
    };



    const fetchGroupTemples = (gid) => {
        // Find the object in nList with the matching gid
        const group = nList.find(group => group.gid === gid);

        if (group) {
            // If a matching group is found, extract the group name
            const groupName = group.group_name;

            // Dispatch fetchTemplesByGroup and navigate with gid and groupName
            dispatch(fetchTemplesByGroup(storedLanguage, gid, 0));
            navigate(`/templeGroup/${gid}`);
        } else {
            // Handle the case where no matching group is found
            console.error('Group not found for gid:', gid);
        }
    };



    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));
    console.log(nList)

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups(storedLanguage));
    }, [storedLanguage]);

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);

    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const handleRecentlyViewed = () => {
        navigate('/recentlyViewed')
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div class="mainsec">
            {/* <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_temples_list')}</span>
                </div>
                <div></div>
            </div> */}
            <div className='breadcrum '>

                <div className='breadcrum-block'>
                    <div class="d-flex">
                        <Link to='/' className='search-back'><i class="fa-solid fa-angle-left"></i></Link>
                        <Breadcrumb
                            items={[
                                {

                                    title: <Link to='/'><span style={{ color: 'white' }}>Home</span></Link>
                                },
                                {
                                    title: (
                                        <>

                                            <span style={{ color: 'white' }}>{("label_temples_list")}</span>
                                        </>
                                    ),
                                },

                            ]}
                        />
                    </div>
                    <div className='Ind'>
                        <Typography.Text style={{ color: 'white' }}>Ind -  {selectedLanguage}</Typography.Text>
                        <span className="color-yellow" onClick={() => setDialogVisible(true)}>
                            <i className="fa-regular fa-pen-to-square"></i>
                        </span>
                    </div>
                </div>


                <Modal
                    title={t("label_select_language")}
                    visible={dialogVisible}
                    onCancel={() => setDialogVisible(false)}
                    onOk={() => setDialogVisible(false)}

                >

                    <Select
                        value={selectedLanguage}
                        onChange={handleChange}
                        options={AP_LANGUAGES}
                    />
                </Modal>
            </div>

            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t("label_temples_list")}</span>
                </div>
                <div></div>
            </div>



            <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>

                <span >
                    <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> - </span>
                <span class="bc-link">{t("label_temples_list")}</span>
            </div>
            <div class="mainsec-block">
                <div class="f2 font-weight-bold  container title-head-txt " style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }}>
                    {token ? (
                        <Link to='/addPage'>
                            <button type="button" class="declineButton">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                            <button type="button" class="declineButton">{t("add_temple")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                    {token ? (
                        <Link to='/recentlyViewed'>
                            <button type="button" className="declineButton" onClick={() => { handleRecentlyViewed() }}>
                                {t("lable_recent_view")} <i className="fa-solid fa-circle-right animate__animated animate__heartBeat"></i>
                            </button>
                        </Link>
                    ) : <Link className="my-link2" onClick={handleLinkClick}>
                        <button type="button" class="declineButton ">{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>}
                    <Link to='/nearby'>
                        <button type="button" class="declineButton">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'>
                            <button type="button" class="declineButton">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}>
                            <button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button></Link>
                    )}
                </div>


                {/* <div className='f2 font-weight-bold mb-2 mt-4 container title-head-txt'>
                
                Temple Categories</div> */}
                <div className="container">
                    <div class="container">
                        <h5 class="card-text-title">{t("Categories")}
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </h5>
                    </div>
                    <div className="tiles-main">
                        <Link to='/templesList'>
                            <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                <div class="title-img-bg">
                                    <img class="card-img-temple-cat" alt="temples" src={famousTemples} />
                                </div>
                                <div class="tile-text-temple-cat">
                                    <span>{t("label_fam_temples")}</span> <span>{("label_temples_list")}</span>
                                </div>
                            </div>
                        </Link>
                        {nLoading ? (
                            <Skeleton active />
                        ) :
                            nList && nList
                                .filter(temple => !temple.group_name.startsWith("Temples in" || temple.gid === 507))
                                .map((temple, index) => (
                                    <div className="tiles-design-temple-cat animate__animated animate__flipInX" key={index} onClick={() => {
                                        if (temple.group_name.toLowerCase() === 'outside of india') {
                                            navigate('/outsideIndia');
                                        }
                                        else if (temple.gid === 507) {
                                            navigate(`/inIndia/${temple.gid}`);
                                        }
                                        else {
                                            fetchGroupTemples(temple.gid);
                                        }
                                    }}>
                                        <div className="title-img-bg ">
                                            <img className="card-img-temple-cat " alt="temples" src={Images[temple.imgpath] || defImage} />
                                        </div>
                                        <div className="tile-text-temple-cat">
                                            <span>{temple.group_name}</span> <span>{("label_temples_list")}</span>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>

                <Footer />
            </div>
        </div>



    )

}

export default TempleCtgry;