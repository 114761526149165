/* global adsbygoogle */
import React, { useEffect, useRef } from "react";

const ResponsiveAd = () => {
  const adRef = useRef(null);

  useEffect(() => {
    const loadAd = () => {
      if (adRef.current && window.adsbygoogle) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    };

    if (!window.adsbygoogle) {
      const script = document.createElement("script");
      script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script.onload = loadAd; // Load ad once script is ready
      document.body.appendChild(script);
    } else {
      loadAd();
    }

    return () => {
      if (adRef.current) {
        adRef.current.innerHTML = ""; // Optional cleanup
      }
    };
  }, []);

  return (
    <div style={{ textAlign: "center", margin: "20px 0" }}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3745324756668457"
        data-ad-slot="8252693488"
        data-ad-format="auto"
        data-ad-test="on" // Test ads for development
      ></ins>
    </div>
  );
};

export default ResponsiveAd;
