import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Table, Input, Select, Col } from 'antd';
import { getAppUsersList, getAppUsersListError, getAppUsersListLoading } from '../../redux/selectors/selectors';
import { fetchAppUsers } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';

const { Search } = Input;
const { Option } = Select;

const AppUsers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const records_per_page = 12;
    const [messageApi, contextHolder] = message.useMessage();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedState, setSelectedState] = useState();
    const [selectedRole, setSelectedRole] = useState();

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(15);

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getAppUsersList(state),
        rLoading: getAppUsersListLoading(state),
        rError: getAppUsersListError(state),
    }));
    
    console.log(rList)

    const last_rec = 0

    useEffect(() => {
        dispatch(fetchAppUsers(last_rec, () => {
            console.warn('Fetch App Users success callback');
        }));
    }, [dispatch]);

    const nextTemplesList = async () => {
        setCurrentPage(prevPage => {
            const newPage = prevPage + 1;

            const newLastRec = newPage * records_per_page;
            dispatch(fetchAppUsers(newLastRec));
        }
        )
    }

    const prevTemplesList = () => {
        setCurrentPage(prevPage => {
            if (prevPage > 0) { // Ensure we don't go below the first page
                const newPage = prevPage - 1; // Decrement page


                const newLastRec = newPage * records_per_page; // Calculate new offset for previous page
                dispatch(fetchAppUsers(newLastRec));
            }
        })
    };


    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleReset = () => {
        setSelectedState(undefined);
        setSelectedRole(undefined);
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const filteredData = rList && rList.filter(item => {
        const isStateMatch = selectedState === undefined || String(item.active) === selectedState;
        const isRoleMatch = selectedRole === undefined || item.urole === selectedRole;
        return (item.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.urole.toLowerCase().includes(searchTerm.toLowerCase())) && isStateMatch && isRoleMatch;
    });

    // Handle case when filteredData is null or empty
    const modifiedData = (filteredData && filteredData.length > 0)
        ? filteredData.map((item, index) => ({ ...item, "S.No": index + 1 }))
        : [];

    const startIndex = (currentPage - 1) * pageSize;
    const paginatedData = modifiedData.slice(startIndex, startIndex + pageSize);

    const columns = [
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Name</span>,
            dataIndex: 'fullname',
            key: 'fullname',
            sorter: (a, b) => a.fullname.localeCompare(b.fullname),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Contact Phone</span>,
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone.localeCompare(b.phone),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Email</span>,
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Gender</span>,
            dataIndex: 'gender',
            key: 'gender',
            sorter: (a, b) => (a.gender || '').localeCompare(b.gender || ''),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>User Role</span>,
            dataIndex: 'urole',
            key: 'urole',
            sorter: (a, b) => a.urole.localeCompare(b.urole),
        },
    ];
    ;

    // Handle Next and Prev button clicks
    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (startIndex + pageSize < modifiedData.length) {
            setCurrentPage(prev => prev + 1);
        }
    };

    return (
        <>
            <div className='mainsec'>
                {contextHolder}
                
                <div className="mob-nav fluid-container page-title justify-content-between">
                    <div className="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i className="fa-solid fa-angle-left"></i></Link>
                        <span className="bc-link">{t('label_app_users')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">Home</span>
                    </Link> - <span className="bc-link">{t("label_app_users")}</span>
                </div>
                <div className="mainsec-block">
                    <div className="container">
                        <h5 className="card-text-title">{t("label_app_users")} <span>| {t("label_app_users_contents")}</span></h5>
                    </div>
                    <div className='container'>
                        <div style={{ display: 'flex', marginBottom: 16 }}>
                            <Search
                                placeholder="Search by fullname or user role"
                                onSearch={handleSearch}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{ marginRight: '10px', width: "30%" }}
                            />
                            <Select
                                showSearch
                                name="state"
                                value={selectedState}
                                onChange={(value) => setSelectedState(value)}
                                className="select-element"
                                style={{ width: '25%', marginRight: '16px' }}
                                placeholder='-- Please select State --'
                            >
                                <Option value="true">{t('label_true')}</Option>
                                <Option value="false">{t('label_false')}</Option>
                            </Select>

                            <Select
                                showSearch
                                name="role"
                                value={selectedRole}
                                onChange={(value) => setSelectedRole(value)}
                                className="select-element"
                                style={{ width: '25%', marginRight: '16px' }}
                                placeholder='-- Please select user role --'
                            >
                                <Option value="AD">{t('label_app_admin')}</Option>
                                <Option value="AS">{t('label_app_support')}</Option>
                                <Option value="PL">{t('label_priest_leader')}</Option>
                                <Option value="PR">{t('label_priest')}</Option>
                                <Option value="TA">{t('label_ta')}</Option>
                                <Option value="TU">{t('label_tu')}</Option>
                                <Option value="SU">{t('label_standard_user')}</Option>
                            </Select>

                            <div>
                                <a   className="button_move" onClick={handleReset}>
                                    Reset
                                </a>
                            </div>
                        </div>
                        <Table dataSource={paginatedData} columns={columns} bordered style={{ border: '2px solid black', borderCollapse: 'collapse' }} pagination={false} />
                        <div className="next-row">
                            {rList && rList.length > 0 ? (
                                <Col>
                                    <a
                                        className="button_move"
                                        onClick={currentPage === 1 ? null : prevTemplesList}
                                        disabled={currentPage === 1}
                                    >
                                        Prev
                                    </a>
                                </Col>
                            ) : (
                                ''
                            )}
                            {rList && rList.length > 0 ? (
                                <Col>
                                    <a
                                        className="button_move"
                                        onClick={rList.length < 15 ? null : nextTemplesList}
                                        disabled={rList.length < 15}
                                    >
                                        Next
                                    </a>
                                </Col>
                            ) : (
                                ''
                            )}
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default AppUsers;
