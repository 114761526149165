import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select, Typography, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceError, getResourceList, getResourceLoading, getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import { addTempleToGroup, fetchResource, fetchTempleGroups, publishResource, removeResource } from '../../../../redux/actions/acions';
import { getImageAPI } from '../../../../http-common';
import { Link, useNavigate, useParams } from 'react-router-dom';
import deftemples from '../../../../assets/images/templeicon.png';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';


function TempleData() {
    const { t } = useTranslation();
    const { gid } = useParams();
    const phoneRegex = /^[0-9]+$/;
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [templeid, setTempleid] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);
    const rid = useParams();

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_tempgrp_success'))
        Form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_tempgrp_failure'))
    };

    const dispatch = useDispatch();
    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getTempleGroupList(state),
        nLoading: getTempleGroupLoading(state),
        nError: getTempleGroupError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchTempleGroups(storedLanguage, 0));
    }, [storedLanguage]);

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
    }, [])

    const openDialog = (group) => {
        setSelectedGroup(group);
        setDialogVisible(true);
    };

    const handleDialogSubmit = () => {
        if (selectedGroup) {
            dispatch(addTempleToGroup(selectedGroup.gid, templeid, success, failure));
            setDialogVisible(false);
        }
    };
    const handleDialogueCancel = () => {
        setDialogVisible(false)
    }

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const handlePopState = (event) => {
            if (dialogVisible) {
                handleDialogueCancel();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (dialogVisible && event.key === 'Escape') {
                handleDialogueCancel();
                event.preventDefault();
            }
        };
        if (dialogVisible) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [dialogVisible, handleDialogueCancel]);
    

    return (
        <>
            {contextHolder}
            <CustomToastContainer />
            <div class='mainsec'>
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_tmpl_groups_list')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">Home</span></Link> - <span className="bc-link">{t("label_tmpl_groups_list")}</span>
                </div>
                <div class="bg-details-highlight" >
                <div class="details-headertxt-singleLine container">
                        <div class="header_txt"> {t("label_tmpl_groups_list")}</div>
                        <Link to='/addTempleGroup/0'><a class="button_move">{t('label_create_temple_group')}</a></Link>
                    </div>
                </div>
                <div class="popup-bg" style={{ display: dialogVisible ? 'flex' : 'none' }}>
                    <div class="popup-main animate__animated animate__fadeInUp ">
                        <div class="popup-main-header">
                            <span class="popup-main-header-text">{t('label_enter_temple_id')}</span>
                            <div class="popup-close" onClick={handleDialogueCancel}><i class="fa-solid fa-xmark"></i></div>
                            <div class="popup-back" onClick={handleDialogueCancel}><i class="fa-solid fa-chevron-left"></i></div>
                        </div>
                        <div class="popup-main-container">
                            <div class="d-block htinner-popup-main-container">
                                <Form.Item

                                    label={t("label_temple_id")}
                                    name="templeid"
                                    rules={[
                                        { required: true, message: 'Please enter TempleID' },
                                        {
                                            pattern: phoneRegex,
                                            message: 'Please enter a valid Phone Number! Only numbers are allowed.',
                                        },
                                    ]}
                                > <Input
                                        value={templeid}
                                        onChange={(e) => setTempleid(e.target.value)}
                                    />

                                </Form.Item>
                            </div>
                        </div>
                        <div class="popup-main-footer">
                            <a className="button_move" onClick={handleDialogueCancel} style={{ float: 'left', marginRight: "5px" }}>
                                {t("label_cancel")}
                            </a>
                            <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { handleDialogSubmit() }}>
                                {t("label_save")}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="mainsec-block-addtemple-detailspage">
                    <div className='container'>
                        <section className="container h-100 d-flex flex-column">
                            <div className="search-rt-sec1 ">
                                <Row>
                                    {
                                        nList && nList.map(temple => {
                                            return (
                                                <Col className='youtube-video' key={temple.rid}>
                                                    <div class="tiles-design animate__animated animate__flipInX">
                                                        <div >

                                                            <div class="card-body p-0">
                                                                <Link to={`/groupDetails/${temple.gid}`} >
                                                                    <div class="p-2">
                                                                        <h6 className="home-temple-text" title={temple.group_name}>{temple.group_name}</h6>
                                                                        <p class="small mb-1">{temple.descr}</p>
                                                                        <p className="small mb-1">
                                                                            <a href={temple.src_url} target="_blank" rel="noopener noreferrer">
                                                                                {temple.src_url}
                                                                            </a>
                                                                        </p>
                                                                    </div>
                                                                </Link>
                                                                <div class="tile-quicklink">
                                                                    {/* <Link to={`/addTempleGroup/${temple.gid}`} >
                                                                    <i class="fa-solid fa-edit" ></i>
                                                                </Link> */}
                                                                    <i class="fa-solid fa-circle-plus" onClick={() => openDialog(temple)}  ></i>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default TempleData;
