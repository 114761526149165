import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Modal, Popconfirm, Select, Table, message } from 'antd';
import { getTempleDashboardList, getTempleDashboardListError, getTempleDashboardListLoading, getTempleMembersList, getTempleMembersListError, getTempleMembersListLoading } from '../../../../redux/selectors/selectors';
import { deleteTempleUser, fetchTempleDashboard, fetchTempleMembers, postAddUserToTemple, updateUserRole } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import { Option } from 'antd/es/mentions';
import Footer from '../footer/footer';
import statesData from '../../states.json';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';

const TempleDashboard = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { id } = useParams()
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [userid, setUserid] = useState('');
    const phoneRegex = /^[0-9]+$/;
    //const [tid, setTid] = useState('');
    const states = statesData.states.map((state) => state.name);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [filteredStates, setFilteredStates] = useState([]);
    const [filteredCities, setFilteredCities] = useState([]);

    const success = () => {
        messageApi.open({
            type: 'success',
            content: `Added User to Temple`,
        });
    };

    const roleSuccess = () => {
        showToast('success', t('Updated Role Sucessfully'))
    };

    const failure = (msg) => {
        showToast('error', msg)
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleDashboardList(state),
        rLoading: getTempleDashboardListLoading(state),
        rError: getTempleDashboardListError(state),
    }));



    const name = localStorage.setItem('tname', rList && rList.tname)
    const tname = localStorage.getItem('tname')

    useEffect(() => {
        dispatch(fetchTempleDashboard());
    }, [storedLanguage, dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: rList.fullname || '',
        // gender: rList.gender || '',
        // lang: rList.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');
    const tid = localStorage.getItem('token');

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryChange = (value) => {
        setSelectedCategory(value);
    };

    const { mList, mLoading, mError } = useSelector(state => ({
        mList: getTempleMembersList(state),
        mLoading: getTempleMembersListLoading(state),
        mError: getTempleMembersListError(state),
    }));

    useEffect(() => {
        dispatch(fetchTempleMembers(0));
    }, [dispatch]);

    const modifiedData = mList && mList.map((item, index) => ({ ...item, "S.No": index + 1 }));

    function deleteDon(record) {
        dispatch(deleteTempleUser(record.userid));
        window.location.reload()
    }

    const servicesData = {
        Darshan: {
            dar: ['Free Darshan', 'Special Darshan', 'VIP Darshan'],
        },
        Puja: {
            special_pujas: ['Daily Pujas', 'Vip Darshan'],
            daily_pujas: ['Daily Gothra Puja', 'Monthly Gothra Puja', 'Abhishekam'],
        },
        Decoration: {
            flowers: ['Flowers'],
            gandham: ['Gandham'],
        },
        Seva: {
            kalyanam: ['Kalyanam'],
            '108_nama_japam': ['108 Nama Japam'],
        },
        Utsav: {
            pallaki_seva: ['Pallaki Seva'],
            grama_utshav: ['Grama Utshav'],
        },
        Accommodation: {
            non_ac_room: ['NON-AC Room'],
            ac_room: ['AC Room'],
            non_ac_dormitory: ['NON-AC Dormitory'],
            ac_dormitory: ['AC Dormitory'],
        },
    };

    //const modifiedData = ''
    const [editingRow, setEditingRow] = useState(null);


    const handleRoleChange = (value, record) => {
        const userId = record.userid;
        dispatch(updateUserRole(userId, { urole: `${value}` }, roleSuccess, failure));
        setEditingRow(null);
    };

    const handleEditClick = (record) => {
        setEditingRow(record.id);
    };

    const uid = localStorage.getItem('urole')
    const columns = [


        // {
        //     title: (
        //         <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
        //             Temple ID
        //         </span>
        //     ),
        //     dataIndex: "tid",
        //     key: "tid",
        //     sorter: (a, b) => a.name.localeCompare(b.name),
        //     render: (text, record) => (
        //         <Link className="notification-link" to={`/donationDetails/${record.donid}`}><span className='donation-text' >{text}</span></Link>
        //     ),
        // },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_id')}
                </span>
            ),
            dataIndex: "userid",
            key: "userid",
            sorter: (a, b) => a.category.localeCompare(b.category),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_name')}
                </span>
            ),
            dataIndex: "fullname",
            key: "fullname",
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_role')}
                </span>
            ),
            dataIndex: 'urole',
            key: 'urole',
            render: (text, record) => (
                <div style={{ minWidth: 120, minHeight: 32 }}>
                    {uid === 'TA' && editingRow === record.id ? (
                        <Select
                            defaultValue={text === 'TA' ? 'Temple Admin' : 'Temple User'}
                            style={{ width: '50%' }}
                            onChange={(value) => handleRoleChange(value, record)}
                            onBlur={() => setEditingRow(null)}
                        >
                            <Option value="TA">{t('Temple Admin')}</Option>
                            <Option value="TU">{t('Temple User')}</Option>
                        </Select>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', minHeight: 32 }}>
                            <span>{text === 'TA' ? 'Temple Admin' : text === 'TU' ? 'Temple User' : text}</span>
                            <i
                                className="fa-solid fa-edit"
                                style={{ marginLeft: 8, cursor: 'pointer', color: '#007bff' }}
                                onClick={() => handleEditClick(record)}
                            ></i>
                        </div>
                    )}
                </div>
            ),

        },
        {
            title: (
                <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>
                    {t('label_actions')}
                </span>
            ),
            dataIndex: 'Delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure you want to Remove User?"
                    onConfirm={() => {
                        deleteDon(record);
                    }}
                    okText="Yes"
                    cancelText="No"
                    style={{ fontWeight: "bold" }}
                >
                    <Link>{t('label_delete')}</Link>
                </Popconfirm>

            ),
        },
    ];

    const [selectedCity, setSelectedCity] = useState("All");
    const [state, setState] = useState(null);

    const handleStateChange = (value) => {
        // Update the selected state and list of cities
        const newState = statesData.states.find((state) => state.name === value);
        setSelectedState(newState);
        setCities(newState ? newState.cities : []);
    };

    useEffect(() => {
        form.setFieldsValue(rList);
    }, [rList]);

    useEffect(() => {
        if (selectedState) {
            form.setFieldsValue({ city: selectedState.cities[0] });
        }
    }, [selectedState]);


    ;

    const handleStateSearch = (value) => {
        const filteredStates = states.filter((stateName) =>
            stateName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filteredStates);
    };

    // Function to handle city search
    const handleCitySearch = (value) => {
        const cities = state ? state.cities : [];
        const filteredCities = cities.filter((cityName) =>
            cityName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filteredCities);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const handlePopState = (event) => {
            if (isModalOpen) {
                setIsModalOpen();
                event.preventDefault();
            }
        };
        const handleKeyDown = (event) => {
            if (isModalOpen && event.key === 'Escape') {
                setIsModalOpen();
                event.preventDefault();
            }
        };
        if (isModalOpen) {
            // Add history entry and listeners
            window.history.pushState({ popupOpen: true }, '');
            window.addEventListener('popstate', handlePopState);
            window.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            // Cleanup listeners when popup closes or component unmounts
            window.removeEventListener('popstate', handlePopState);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isModalOpen, setIsModalOpen]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const Success = () => {
        showToast('success', t('label_edit_hist_sucess'))

    };

    const Failure = (msg) => {
        showToast('error', t(msg))

    };

    const handleModalSubmit = () => {
        // Add form validation logic here if needed

        // Dispatch the action with tid and userid
        dispatch(postAddUserToTemple(userid, Success));
        setIsModalOpen(false);
        window.location.reload()
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // Return an empty string if string is null or undefined
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_my_temple_dashboard')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-active-link" onClick={handleGoBack}>{t('label_profile')}</span> - <span className="bc-link">{t("label_my_temple_dashboard")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt-singleLine container">
                    <div class="header_txt"> {t('label_my_temple_dashboard')}</div>
                    <div class="details-headertxt-singleLine-btns">
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                            <Link to={'/donations'} style={{ marginLeft: '5px' }} className="button_move">{t('label_donations')}</Link>
                            <Link to={'/expenses'} style={{ marginLeft: '5px' }} className="button_move">{t('label_expenses')}</Link>

                            {rList && rList.tid ? (
                                <Link to={`/templeDetails/${rList.tid}?prevPage=${window.location.pathname}`} style={{ marginLeft: '5px' }} className="button_move">
                                    {t('label_tpl_det')}
                                </Link>
                            ) : (
                                <span style={{ marginLeft: '5px' }}>{t('label_tpl_det')}</span>
                            )}
                        </div>

                    </div>

                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    {/* <Link to={`/editHoroscope/${rList&&rList.hid}`}>
                    <EditOutlined className="edit-icon" title="Edit" />
                </Link> */}
                    {rLoading ? (
                        <p>Loading...</p>
                    ) : (
                        rList && (
                            <div className='horoscope-item' >
                                <div class="float-left w-100" ><h5 class="card-text-title">{t("label_details")} </h5></div>
                                {/* <h2>{rList.author}</h2> */}
                                <Form form={form}>
                                    <div class="active-details">
                                        <div class="tiles-designs" >
                                            <Form.Item
                                                label={<Typography.Title level={4} >{t("label_temple_name")}</Typography.Title>}
                                                name="templename"

                                            >
                                                <Typography.Text level={5} className='typ'>{rList && rList.tname}</Typography.Text>
                                            </Form.Item>

                                        </div>

                                        <div class="tiles-designs" >
                                            <Form.Item
                                                label={<Typography.Title level={4} >{t("label_deity_name")}</Typography.Title>}
                                                name="templename"

                                            >
                                                <Typography.Text level={5} className='typ'>{rList && rList.deityname}</Typography.Text>
                                            </Form.Item>

                                        </div>
                                        <div class="tiles-designs" >
                                            <Form.Item
                                                label={<Typography.Title level={4} >{t("label_addr")}</Typography.Title>}
                                                name="lnk"

                                            >

                                                <Typography.Text level={5} className='typ'>{rList && rList.addr1}</Typography.Text>

                                            </Form.Item>

                                        </div>
                                        <div class="tiles-designs" >
                                            <Form.Item
                                                // style={{ marginLeft: '2.5%' }}
                                                label={<Typography.Title level={4} >{t("label_city")}</Typography.Title>}
                                                showSearch
                                                onChange={(value) => setSelectedCity(value)}
                                                onSearch={(value) => handleCitySearch(value)}
                                                name="city"
                                            >
                                                <Typography.Text level={5} className='typ'>
                                                    {capitalizeFirstLetter(rList && rList.city ? rList.city : t("label_notchosen"))}
                                                </Typography.Text>
                                            </Form.Item>
                                            {/* </Col>
          <Col span={12}> */}


                                        </div>
                                        <div className="tiles-designs">
                                            <Form.Item
                                                style={{ marginTop: '5px' }}
                                                label={<Typography.Title level={4}>{t("label_state_ap")}</Typography.Title>}
                                                showSearch
                                                onChange={(value) => handleStateChange(value)}
                                                onSearch={(value) => handleStateSearch(value)}
                                                name="st"
                                            >
                                                <Typography.Text level={5} className='typ'>
                                                    {capitalizeFirstLetter(rList && rList.st ? rList.st : t("label_notchosen"))}
                                                </Typography.Text>
                                            </Form.Item>
                                        </div>





                                        <div class="tiles-designs" >
                                            <Form.Item
                                                label={<Typography.Title level={4} >{t("label_email")}</Typography.Title>}
                                                name="src"

                                            >

                                                <Typography.Text level={5} className='typ'>{rList && rList.email}</Typography.Text>

                                            </Form.Item>

                                        </div>
                                        <div class="tiles-designs" >
                                            <Form.Item
                                                label={<Typography.Title level={4} >{t("label_website")}</Typography.Title>}
                                                name="src"
                                            >
                                                <a
                                                    href={rList.website.startsWith("http") ? rList.website : `http://${rList.website}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {rList.website}
                                                </a>
                                            </Form.Item>
                                        </div>
                                        <div class="tiles-designs" >
                                            <Form.Item
                                                label={<Typography.Title level={4} >{t("label_phone")}</Typography.Title>}
                                                name="src"

                                            >

                                                <Typography.Text level={5} className='typ'>{rList && rList.phoneno}</Typography.Text>

                                            </Form.Item>

                                        </div>
                                    </div>
                                </Form>

                            </div>
                        )
                    )}
                </div>
                <div className="container-fluid" style={{ display: 'flex', justifyContent: 'end' }}>
                    {uid === 'TA' || 'TU' ? (
                        <button type="button" class="btn1 btn btn-secondary " onClick={() => { setIsModalOpen(true); }} style={{ marginRight: '18%', marginBottom: '5px' }}>{t('label_add_member')}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button>
                    ) : ('')}

                    {/* <Modal
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={
                            <Button
                                type="primary"
                                className="button_move"
                                style={{ width: '25%', marginBottom: '5px', marginRight: '5px' }}
                                onClick={handleCancel}
                            >
                                {t("label_cancel")}
                            </Button>
                        }
                    >
                        <div className="diag-header">
                            {t('label_enter_member_id')}
                            <span className="diag-close">
                                <i className="fa-solid fa-xmark" onClick={handleCancel}></i>
                            </span>
                        </div>

                        <Form
                            name="nest-messages"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{ padding: '10px 10px' }}
                        >
                            <div className="active-details">
                                <div className="tiles-designs">
                                    <Form.Item
                                        label="User ID"
                                        name="userid"
                                        rules={[
                                            { required: true, message: 'Please enter UserID' },
                                        ]}
                                    >
                                        <Input
                                            value={userid}
                                            onChange={(e) => setUserid(e.target.value)}
                                        />
                                    </Form.Item>

                                    <Form.Item wrapperCol={{ span: 24 }} style={{ marginTop: '5px' }}>
                                        <Button
                                            type="primary"
                                            className="button_move"
                                            style={{
                                                width: '25%',
                                                marginBottom: '5px',
                                                marginRight: '5px',
                                                backgroundColor: '#F4801E',
                                                borderColor: '#F4801E',
                                                color: '#fff'
                                            }}
                                            onClick={handleModalSubmit}
                                        >
                                            {t("label_save")}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </Modal> */}

                    <div class="popup-bg" style={{ display: isModalOpen ? 'flex' : 'none' }}>
                        <div class="popup-main animate__animated animate__fadeInUp ">
                            <div class="popup-main-header">
                                <span class="popup-main-header-text">{t('label_enter_member_id')}</span>
                                <div class="popup-close" onClick={handleCancel}><i class="fa-solid fa-xmark"></i></div>
                                <div class="popup-back" onClick={handleCancel}><i class="fa-solid fa-chevron-left"></i></div>
                            </div>
                            <div class="popup-main-container">
                                <div class="d-block htinner-popup-main-container">
                                    <Form.Item
                                        label="User ID"
                                        name="userid"
                                        rules={[
                                            { required: true, message: 'Please enter UserID' },
                                        ]}
                                    >
                                        <Input class="dialog-content-text-only"
                                            style={{ padding: '5px 10px', whiteSpace: "pre-wrap" }}
                                            value={userid}
                                            onChange={(e) => setUserid(e.target.value)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="popup-main-footer">
                                <a className="button_move" onClick={handleCancel} style={{ float: 'left', marginRight: "5px" }}>
                                    {t("label_cancel")}
                                </a>
                                <a type="primary" className="button_move" style={{ float: 'right' }} onClick={() => { handleModalSubmit() }}>
                                    {t("label_save")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className='container'> {t('label_temple_team')}</h2>
                <div className='container'>
                    <Table dataSource={mList} columns={columns} bordered rowKey="id"
                        style={{ marginBottom: '10px', border: '2px solid black', borderCollapse: 'collapse' }}
                    />
                </div>
                <Footer />
            </div >
        </div>

    );
};

export default TempleDashboard;
