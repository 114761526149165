import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, message, Row, Col, Image, Result, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addFavourites, fetchContributions, fetchFavourites, fetchGodNames, fetchResArticles, fetchResAudios, fetchResLivetv, fetchResMantras, fetchResVideos, fetchResourceFavourites, fetchTemplesList, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import { getAudioAPI, getImageAPI } from "../../../../http-common";
import deftemples from '../../../../assets/images/templeicon.jpg';
import { getArticlesError, getArticlesList, getArticlesLoading, getAudios, getAudiosError, getAudiosLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getMantras, getMantrasError, getMantrasLoading, getResArticles, getResArticlesError, getResArticlesLoading, getResAudiosError, getResAudiosList, getResAudiosLoading, getResLivetvError, getResLivetvList, getResLivetvLoading, getResVideosError, getResVideosList, getResVideosLoading, getResourceFavouritesList, getResourceFavouritesLoading, getTemplesList, getTemplesListError, getTemplesListLoading, getVideosError, getVideosLoading } from '../../../../redux/selectors/selectors';
import Error from '../error';
import Footer from '../footer/footer';
import YouTube from './youtube';
import showToast from '../../../../utils/showToast';
import TempleCard from '../../../common/card';

let last_rec = 0;

const ResourceFavourite = () => {
    const records_per_page = 12;
    const [filterId, setFilterId] = useState(null);
    const localEnv = false;
    const [isLoading, setIsLoading] = useState(false);
    const [did, setDid] = useState(0);
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const [totalRecords, setTotalRecords] = useState(0);
    const dispatch = useDispatch();
    const { fList, fLoading, fError } = useSelector(state => ({
        fList: getResourceFavouritesList(state),
        fLoading: getResourceFavouritesLoading(state),
        fError: getFavouritesError(state),
    }));
    const { aList, aLoading, aError } = useSelector(state => ({
        aList: getResArticles(state),
        aLoading: getResArticlesLoading(state),
        aError: getResArticlesError(state),
    }));
    console.log(aList)
    const { mList, mLoading, mError } = useSelector(state => ({
        mList: getMantras(state),
        mLoading: getMantrasLoading(state),
        mError: getMantrasError(state),
    }));

    const { audioList, audioLoading, audioError } = useSelector(state => ({
        audioList: getResAudiosList(state),
        audioLoading: getResAudiosLoading(state),
        audioError: getResAudiosError(state),
    }));

    const { lList, lLoading, lError } = useSelector(state => ({
        lList: getResLivetvList(state),
        lLoading: getResLivetvLoading(state),
        lError: getResLivetvError(state),
    }));

    const { vList, vLoading, vError } = useSelector(state => ({
        vList: getResVideosList(state),
        vLoading: getResVideosLoading(state),
        vError: getResVideosError(state),
    }));

    useEffect(() => {
        dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
    }, [storedLanguage]);

    const [filled, setFilled] = useState({}); // initialize as an empty object
    const [refresh, setRefresh] = useState(false);
    const [selectedContent, setSelectedContent] = useState('books');
    const getToken = Boolean(localStorage.getItem('token'));
    const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [currentPage, setCurrentPage] = useState(0);
    const [lastRec, setLastRec] = useState(0);
    const [currentList, setCurrentList] = useState([]);

    const success = (tname) => {
        showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
    };

    const warning = (tname) => {
        showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
    };

    const failure = (msg) => {
        showToast('error', t('label_loginerror'))
    };

    function toggleFilled(id) {
        setFilled(prevFilled => ({
            ...prevFilled,
            [id]: !prevFilled[id]
        }));
    }

    function addFavourites(rid) {
        dispatch(postBooksFavourites('books', rid, failure));
        setFavList(prevFavList => {
            const updatedFavList = [...prevFavList, rid];
            localStorage.setItem('books', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function deleteFavourites(deleteId) {
        dispatch(removeBooksFavourites('books', deleteId, failure));
        setFavList(prevFavList => {
            const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
            localStorage.setItem('books', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function addArticleFavourites(rid) {
        dispatch(postBooksFavourites('articles', rid, failure));
        setFavList(prevFavList => {
            const updatedFavList = [...prevFavList, rid];
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function deleteArticleFavourites(deleteId) {
        dispatch(removeBooksFavourites('articles', deleteId, failure));
        setFavList(prevFavList => {
            const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function addMantrasFavourites(rid) {
        dispatch(postBooksFavourites('articles', rid, failure));
        setFavList(prevFavList => {
            const updatedFavList = [...prevFavList, rid];
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    function deleteMantrasFavourites(deleteId) {
        dispatch(removeBooksFavourites('articles', deleteId, failure));
        setFavList(prevFavList => {
            const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
            localStorage.setItem('articles', JSON.stringify(updatedFavList));
            return updatedFavList;
        });
    }

    useEffect(() => {
        dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
    }, [storedLanguage, refresh]);


    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'));
    }, []);


    // const getCurrentList = () => {
    //     let list;
    //     switch (activeKey) {
    //         case 'books':
    //             list = fList || [];
    //             break;
    //         case 'articles':
    //             list = aList || [];
    //             break;
    //         case '3': // Mantras
    //             list = mList || [];
    //             break;
    //         case '4': // Audios
    //             list = audioList || [];
    //             break;
    //         case '5': // Live TV
    //             list = lList || [];
    //             break;
    //         case '6': // Videos
    //             list = vList || [];
    //             break;
    //         default:
    //             list = [];
    //             break;
    //     }
    //     console.log(`List for activeKey ${activeKey}:`, list);
    //     return list;
    // };

    // // Fetch current list based on activeKey
    // const currentList = getCurrentList();

    // useEffect(() => {
    //     if (!isLoading && currentList.length > 0) {
    //         console.log("Data loaded successfully. Current list length:", currentList.length);
    //     }
    // }, [isLoading, activeKey, currentList]);

    // useEffect(() => {
    //     if (fList && fList.length > 0) {
    //         setTotalRecords(fList && fList[0] && fList[0].totalCount ? fList[0].totalCount : 0);
    //     } else if (aList && aList.length > 0) {
    //         setTotalRecords(aList && aList[0] && aList[0].totalCount ? aList[0].totalCount : 0);

    //     }
    // }, [fList, aList]);

    const fetchData = async (activeKey, offset) => {
        setIsLoading(true);
        try {
            let response;
            switch (activeKey) {
                case 'books':
                    response = await dispatch(fetchResourceFavourites('books', storedLanguage, offset));
                    break;
                case 'articles':
                    response = await dispatch(fetchResArticles(storedLanguage, offset));
                    break;
                case '3': // Mantras
                    response = await dispatch(fetchResMantras(storedLanguage, offset));
                    break;
                case '4': // Audios
                    response = await dispatch(fetchResAudios(storedLanguage, offset));
                    break;
                case '5': // Live TV
                    response = await dispatch(fetchResLivetv(storedLanguage, offset));
                    break;
                case '6': // Videos
                    response = await dispatch(fetchResVideos(storedLanguage, offset));
                    break;
                default:
                    break;
            }

            // Assuming the response is an array of data, update the state accordingly
            setCurrentList(response || []);
        } finally {
            setIsLoading(false);
        }
    };

    const [offsets, setOffsets] = useState({
        books: 0,
        articles: 0,
        mantras: 0,
        audios: 0,
        livetv: 0,
        videos: 0,
    });

    const nextTemplesList = () => {
        const nextOffset = offsets[activeKey] + records_per_page;
        setOffsets(prev => ({ ...prev, [activeKey]: nextOffset }));
        fetchData(activeKey, nextOffset);
    };

    const prevTemplesList = () => {
        const prevOffset = Math.max(offsets[activeKey] - records_per_page, 0);
        setOffsets(prev => ({ ...prev, [activeKey]: prevOffset }));
        fetchData(activeKey, prevOffset);
    };

    useEffect(() => {
        switch (activeKey) {
            case 'books':
                setCurrentList(fList);
                break;
            case 'articles':
                setCurrentList(aList);
                break;
            case '3':
                setCurrentList(mList);
                break;
            case '4':
                setCurrentList(audioList);
                break;
            case '5':
                setCurrentList(lList);
                break;
            case '6':
                setCurrentList(vList);
                break;
            default:
                setCurrentList([]);
        }
    }, [activeKey, fList, aList, mList, audioList, lList, vList]);

    // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.tid);

    // function toggleFavorite(temple) {
    //     const index = favList.findIndex(t => t === temple.rid);
    //     if (index === -1) {
    //         addFavourites(temple.rid, temple.title);
    //         setGetFavoriteTemples(!getFavouriteTemples);
    //         if (getToken) {
    //             success(temple.title);
    //         }
    //     } else {
    //         deleteFavourites(temple.rid, temple.title);
    //         setGetFavoriteTemples(!getFavouriteTemples);
    //         setRefresh(!refresh);
    //     }

    // }
    const [favList, setFavList] = useState([]);

    useEffect(() => {
        if (getToken) {
            const cachedFavourites = JSON.parse(localStorage.getItem('articles'));
            if (cachedFavourites) {
                setFavList(cachedFavourites);
            } else {
                dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
            }
        }
    }, [getToken]);

    useEffect(() => {
        if (fList && fList.fav_list) {
            setFavList(fList.fav_list);
            localStorage.setItem('articles', JSON.stringify(fList.fav_list));
        }
    }, [fList]);



    function toggleFavorite1(temple) {
        if (getToken) {
            const templeId = temple.rid;
            if (favList.includes(templeId)) {
                // Temple is in favList, remove it
                deleteFavourites(templeId, temple.title);
                // warning(temple.title);
                // Update favList by removing the temple ID
                // setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
                dispatch(removeBooksFavourites('articles', templeId, failure));
                setFavList(prevFavList => {
                    const updatedFavList = prevFavList.filter(favId => favId !== templeId);
                    localStorage.setItem('articles', JSON.stringify(updatedFavList));
                    return updatedFavList;
                });
                warning(`${temple.title} removed from favorites`);
            } else {
                // Temple is not in favList, add it
                addFavourites(templeId, temple.title);
                // success(temple.title);
                // Update favList by adding the temple ID
                // setFavList(prevFavList => [...prevFavList, templeId]);
                dispatch(postBooksFavourites('articles', templeId, failure));
                setFavList(prevFavList => {
                    const updatedFavList = [...prevFavList, templeId];
                    localStorage.setItem('articles', JSON.stringify(updatedFavList));
                    return updatedFavList;
                });
                success(`${temple.title} added to favorites`);
            }
        } else {
            // Handle the case when there's no token (e.g., show a message or redirect to login)
            failure("Please log in to favorite temples");
        }
    }
    const [filledTemples, setFilledTemples] = useState({});
    function toggleFilled(id) {
        setFilledTemples((prevFilled) => ({
            ...prevFilled,
            [id]: !prevFilled[id],
        }));
    }


    const temple = fList;

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleNav = () => {
        // navigate(`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`)
    };

    const [activeKey, setActiveKey] = useState('1');

    const onChange = (key) => {
        setActiveKey(key);
    };

    function getYouTubeVideoId(url) {
        // This function extracts the YouTube video ID from a YouTube URL
        const regex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    const handleButtonClick = (contentType) => {
        setSelectedContent(contentType);
        setActiveKey(contentType);
        // Fetch the respective content based on the button clicked
        switch (contentType) {
            case 'books':
                dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
                break;
            case 'articles':
                dispatch(fetchResArticles(storedLanguage, last_rec));
                break;
            case 'mantras':
                dispatch(fetchResMantras(storedLanguage, last_rec));
                break;
            case 'audios':
                dispatch(fetchResAudios(storedLanguage, last_rec));
                break;
            case 'livetv':
                dispatch(fetchResLivetv(storedLanguage, last_rec));
                break;
            case 'videos':
                dispatch(fetchResVideos(storedLanguage, last_rec));
                break;
            default:
                break;
        }
    };

    const renderContent = () => {
        switch (selectedContent) {
            case 'books':
                return renderTiles(fLoading, 'books', fList);
            case 'articles':
                return renderTiles(aLoading, 'articles', aList);
            case 'mantras':
                return renderTiles(mLoading, 'mantras', mList);
            case 'audios':
                return renderTiles(audioLoading, 'audios', audioList);
            case 'livetv':
                return renderTiles(lLoading, 'livetv', lList);
            case 'videos':
                return renderTiles(vLoading, 'videos', vList);
            default:
                return null;
        }
    };

    const renderTiles = (loading, resourceType, list) => {
        list = list || [];
        const isVideoOrLiveTV = resourceType === 'livetv' || resourceType === 'videos';
        const isAudio = resourceType === 'audios';

        return (
            <div className="container search">
                {loading ? (
                    <Error />
                ) : (
                    <div className="tiles-main">
                        {list.length === 0 ? (
                            <Result
                                status="404"
                                title={t("label_nodata_found")}
                                subTitle={`Sorry, no favourite ${resourceType} found.`}
                            />
                        ) : (
                            isVideoOrLiveTV ? (
                                list.map((resource) => (
                                    <YouTube
                                        key={resource.rid}
                                        temple={resource}
                                        toggleFavorite={toggleFavorite1}
                                        toggleFilled={toggleFilled}
                                        favList={favList}
                                        getYouTubeVideoId={getYouTubeVideoId}
                                    />
                                ))
                            ) : (
                                isAudio ? (
                                    list.map((resource, index) => (
                                        <div className="tiles-design" key={resource.rid}>
                                            <Link to={`/editResource/${resource.rid}`} onClick={() => handleNav()}>
                                                {resource.img_path ? (
                                                    <div
                                                        className="card-img"
                                                        style={{
                                                            backgroundImage: `url(${getImageAPI()}${resource.img_path})`
                                                        }}
                                                    ></div>
                                                ) : (
                                                    <div className="title-img-bg">
                                                        <img
                                                            className="card-img"
                                                            alt="resource"
                                                            src={deftemples}
                                                        />
                                                    </div>
                                                )}
                                                <div className="tile-text1">
                                                    <div>
                                                        <h6 className="home-temple-text" title={resource.title}>
                                                            {resource.title}
                                                        </h6>
                                                        <p className="mb-1 f-grey tile-add">
                                                            {resource.author}
                                                        </p>
                                                    </div>
                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',

                                                    }}>
                                                        <audio id={'song00' + resource.rid} controls="controls" onLoadedMetadata={event => console.log("MP3=" + event.target.duration)}>
                                                            <source src={`${getAudioAPI()}${resource.audio_path}`} type="audio/mpeg" />
                                                        </audio>
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className="tile-quicklink">
                                                <Button
                                                    className="share-button"
                                                    type="link"
                                                    onClick={() => {
                                                        if (navigator.share) {
                                                            navigator.share({
                                                                title: resource.title,
                                                                text: `Check out this ${resource.res_type}!`,
                                                                url: `https://www.templeswiki.com/editResource/${resource.rid}`
                                                            })
                                                                .then(() => console.warn("Share successful."))
                                                                .catch((error) => console.error("Error sharing:", error));
                                                        } else {
                                                            console.warn("Web Share API not supported.");
                                                        }
                                                    }}
                                                >
                                                    <i title="Share" className="fa-solid fa-share-nodes"></i>
                                                </Button>
                                                <Button
                                                    className="temple-fav-icon"
                                                    type="link"
                                                    onClick={() => toggleFavorite1(resource, resource.title)}
                                                    icon={
                                                        favList.some((t) => t === resource.rid) ? (
                                                            <i className="fa-solid fa-heart"></i>

                                                        ) : (
                                                            <i className="fa-solid fa-heart"></i>
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>

                                    ))
                                ) : (
                                    list.map((resource) => (
                                        <TempleCard
                                            key={resource.rid}
                                            id={resource.rid}
                                            imgpath={resource.imgpath}
                                            title={resource.title}
                                            city={resource.author}
                                            temple={resource}
                                            favList={favList}
                                            toggleFavorite={toggleFavorite1}
                                            toggleFilled={toggleFilled}
                                            link={resource.src_url}
                                        // target={target}
                                        // handleClick={() => handleArticleClick(resource)}
                                        // text={text}
                                        />
                                    ))
                                )
                            )
                        )}
                    </div>
                )}
            </div>
        );
    };


    useEffect(() => {
        if (activeKey === '1') {
            dispatch(fetchResourceFavourites('books', storedLanguage, last_rec));
        } else if (activeKey === '2') {
            dispatch(fetchResArticles(storedLanguage, last_rec));
        } else if (activeKey === '3') {
            dispatch(fetchResMantras(storedLanguage, last_rec));
        }
        else if (activeKey === '4') {
            dispatch(fetchResAudios(storedLanguage, last_rec));
        }
        else if (activeKey === '5') {
            dispatch(fetchResLivetv(storedLanguage, last_rec));
        }
        else if (activeKey === '6') {
            dispatch(fetchResVideos(storedLanguage, last_rec));
        }
    }, [activeKey, storedLanguage, last_rec, dispatch]);




    return (
        <div className='mainsec'>

            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{t("label_library_fav")}</span>
                </div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> -
                &nbsp;<Link style={{ color: 'black' }} to='/library'><span class="bc-active-link">{t('label_library')}</span></Link> - <span class="bc-link">{t('label_library_fav')}</span>
            </div>
            <div class="mainsec-block">
                {/* <div class="container cat-txt ">
                    <div class="cat-detail">
                        <span class="f3 d-flex justify-content-between">
                            <div><b>{t("label_library_fav")}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
                        </span>
                        <p class="f1">{t('label_fav_page_msg')}</p>
                    </div>
                </div> */}
                <div class="details-icons-main mb-3"><div class="container details-icons">
                    <button
                        className={`details-icons-list  ${selectedContent === 'books' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('books')}
                    >
                        {t('label_books')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'articles' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('articles')}
                    >
                        {t('label_articles')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'mantras' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('mantras')}
                    >
                        {t('label_mantras')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'audios' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('audios')}
                    >
                        {t('label_audios')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'livetv' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('livetv')}
                    >
                        {t('label_livetv')}
                    </button>
                    <button
                        className={`details-icons-list  ${selectedContent === 'videos' ? 'selected' : ''}`}
                        onClick={() => handleButtonClick('videos')}
                    >
                        {t('label_videos')}
                    </button>
                </div></div>
                {renderContent()}


                <div className="next-row">
                    <a
                        className="button_move"
                        onClick={prevTemplesList}
                        disabled={offsets[activeKey] === 0 || isLoading} // Check the offset for the activeKey
                    >
                        {t("label_previous")}
                    </a>

                    <a
                        className="button_move"
                        onClick={nextTemplesList}
                        disabled={currentList.length < records_per_page || isLoading} // Disable if less than a full page or loading
                    >
                        {t("label_next")}
                    </a>

                </div>




                <Footer />
            </div>
        </div>
    );
};

export default ResourceFavourite;
