import React, { useEffect, useState } from 'react';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { getImageAPI } from '../../../../http-common';
import '../../style.css';
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import { Row, Col } from 'react-bootstrap'; // Import Row and Col from react-bootstrap
import TemplesPage from '../../../common/TemplesPage';
import TempleCard from '../../../common/card';
import { useDispatch, useSelector } from 'react-redux';
import { getFavouritesError, getFavouritesList, getFavouritesLoading, getRecentlyViewedError, getRecentlyViewedList, getRecentlyViewedLoading, getTempleFavouritesError, getTempleFavouritesList, getTempleFavouritesLoading } from '../../../../redux/selectors/selectors';
import { fetchFavourites, fetchRecentlyViewed, fetchTempleFavourites, postFavourites, removeFavourites, updateRecentlyViewed } from '../../../../redux/actions/acions';
import { message } from 'antd';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';

function RecentlyViewed() {
  const recentlyViewedTemples = JSON.parse(localStorage.getItem('recentlyViewedTemples')) || [];
  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [messageApi, contextHolder] = message.useMessage();
  const getToken = Boolean(localStorage.getItem('token'));
  
  const success = (tname) => {
    messageApi.open({
      type: 'success',
      content: `Added ${tname} to favorites`,
    });
  };
  const warning = (tname) => {
    messageApi.open({
      type: 'warning',
      content: `Removed ${tname} from favourites`,
    });
  };

  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: 'Please Login to see additional features',
    });
  };

  const dispatch = useDispatch();
  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getTempleFavouritesList(state),
    fLoading: getTempleFavouritesLoading(state),
    fError: getTempleFavouritesError(state),
  }));

  useEffect(() => {
    if (getToken) {
        dispatch(fetchTempleFavourites());
    }
  }, [getToken]);

  const { rList, rLoading, rError } = useSelector(state => ({
    rList: getRecentlyViewedList(state),
    rLoading: getRecentlyViewedLoading(state),
    rError: getRecentlyViewedError(state),
  }));

  useEffect(() => {    
      if (getToken) {            
    dispatch(fetchRecentlyViewed(storedLanguage, 0))
  }
  }, [getFavouriteTemples, refresh])

  function addFavourites(id) {
    dispatch(postFavourites(id, failure))
  }

  function deleteFavourites(deleteId) {
    dispatch(removeFavourites(deleteId, failure))
  }

  const tids = recentlyViewedTemples.map(temple => temple.tid);
  console.warn("Generated tids:", tids);
  
  if (getToken) {
    dispatch(updateRecentlyViewed({"recent" : tids }));
  }
  

  const [favList, setFavList] = useState([]);

  useEffect(() => {
    if (fList) {
      setFavList(fList.fav_list);
    }
  }, [fList]);

 

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.tid;
      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.tname);
        warning(temple.tname);
        // Update favList by removing the temple ID
        setFavList(prevFavList => prevFavList.filter(id => id !== templeId));
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.tname);
        success(temple.tname);
        // Update favList by adding the temple ID
        setFavList(prevFavList => [...prevFavList, templeId]);
      }
    } else {
      // Handle the case when there's no token (e.g., show a message or redirect to login)
      failure("Please log in to favorite temples");
    }
  }

  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilled(prevFilled => ({
      ...prevFilled,
      [id]: !prevFilled[id]
    }));
  }

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
    {contextHolder}
      {getToken ? (
        // User is logged in, display rList
        <>
        
          {rList&&rList.length === 0 ? (
           
            <div className="mainsec">
                      <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('lable_recent_view')}</span>
                </div>
                <div></div>
            </div>
          <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

            <Link to='/'><span className="bc-active-link">{t('Home')}</span></Link> - <Link to='/templeCtgry'><span className="bc-active-link">{t('label_temples_list')}</span></Link> - <span className="bc-link">{t('lable_recent_view')}</span>
          </div>
          <div className="mainsec-block">
            <div className="container cat-txt">
              <div className="cat-detail">
                <span className="f3 d-flex justify-content-between">
                  <div><b>{t("lable_recent_view")}</b> &nbsp; &nbsp;<i className="fa-solid fa-volume-high"></i></div>
                </span>
                <p className="f1">{t('label_recviewed_temples')}
                </p>
              </div>
            </div>
            <div className="container search">
              <div className="tiles-main">
            <Result
              status="404"
              title={t("label_nodata_found")}
              subTitle={t("label_no_recent_temples_msg")}
            />           
           </div>
           </div>
           <Footer/>
           </div>
           </div>
          ) : (
            <TemplesPage
              title={t('lable_recent_view')}
              description={t('label_recviewed_temples')}
              tList={rList}
              tLoading={rLoading}
              pageTitle={t('lable_recent_view')}
            />
          )}
        </>
      ) : (
        <div className="mainsec">
                      <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_priest_by_spclty')}</span>
                </div>
                <div></div>
            </div>
          <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

            <Link to='/'><span className="bc-active-link">{t('Home')}</span></Link> - <Link to='/templeCtgry'><span className="bc-active-link">{t('label_temples_list')}</span></Link> - <span className="bc-link">{t('lable_recent_view')}</span>
          </div>
          <div className="mainsec-block">
            <div className="container cat-txt">
              <div className="cat-detail">
                <span className="f3 d-flex justify-content-between">
                  <div><b>{t("lable_recent_view")}</b> &nbsp; &nbsp;<i className="fa-solid fa-volume-high"></i></div>                 
                </span>
                <p className="f1">{t('label_recviewed_temples')}
                </p>
              </div>
            </div>
            <div className="container search">
              <div className="tiles-main">
                {recentlyViewedTemples.length === 0 ? (
                  <Result
                    status="404"
                    title={t("label_nodata_found")}
                    subTitle={t("label_no_recent_temples_msg")}
                  />
                ) : (
                  recentlyViewedTemples.map((temple, index) => (
                    <TempleCard
                      key={temple.tid} // Add a key prop
                      id={temple.tid}
                      imgpath={temple.imgpath}
                      title={temple.tname}
                      city={temple.city || "Unknown City"} // Provide a default value
                      state={temple.st}
                      favList={favList}
                      toggleFavorite={toggleFavorite}
                      toggleFilled={toggleFilled}
                      link={`/templeDetails/${temple.tid}?prevPage=${window.location.pathname}`}
                    />
                  ))
                )}
              </div>
            </div>
            <Footer/>
          </div>
        </div>
      )}
    </>
  );
}

export default RecentlyViewed;
