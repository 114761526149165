import { Button, Col, Row, Typography, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { getByStDtError, getByStDtList, getByStDtLoading, getCityStDescrList, getCityStDescrListError, getCityStDescrListLoading, getFilterByStDtError, getFilterByStDtList, getFilterByStDtLoading, getGodNames, getGodNamesError, getGodNamesLoading } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../error';
import TemplesPage from '../../../common/TemplesPage';
import { fetchByStDt, fetchCityStDesList, fetchFilterByStDt, fetchGodNames, retrieveGodNames } from '../../../../redux/actions/acions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import deftemples from '../../../../assets/images/templeicon.jpg'


let last_rec = 0;
let god_last_rec = 0;
const StateFilter = () => {
  const [filterId, setFilterId] = useState(null);
  const [currentPageTemples, setCurrentPageTemples] = useState([]);
  const records_per_page = 12;
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { selectedState, selectedCity } = useParams();
  const localEnv = false;
  const [messageApi, contextHolder] = message.useMessage();
  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: 'Please Login to see additional features',
    });
  };

  const { godCtgryList, godCtgryListLoading, godCtgryListError, stList, stLoading, stError, fstList, fstLoading, fstError, gList, gLoading, gError } = useSelector(state => ({
    godCtgryList: getGodNames(state),
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
    stList: getFilterByStDtList(state),
    stLoading: getFilterByStDtLoading(state),
    stError: getFilterByStDtError(state),
    fstList: getByStDtList(state),
    fstLoading: getByStDtLoading(state),
    fstError: getByStDtError(state),
    gList: getCityStDescrList(state),
    gLoading: getCityStDescrListLoading(state),
    gError: getCityStDescrListError(state),
  }))
  
  console.log(stList)

  useEffect(() => {
    dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, last_rec));
  }, [storedLanguage, selectedState, selectedCity, 0]);

  useEffect(() => {
    const lowercaseState = selectedState.toLowerCase();
    const lowercaseCity = selectedCity.toLowerCase();
    dispatch(fetchCityStDesList(storedLanguage, lowercaseState, lowercaseCity));
  }, [storedLanguage, selectedState, selectedCity]);
  

  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage))
  }, [storedLanguage])

  function filterTemplesList(gDid) {
    setDid(gDid);
    if (gDid === filterId) {
      setFilterId(null)
      dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, last_rec));
    } else {
      setFilterId(gDid);
      dispatch(fetchFilterByStDt(storedLanguage, selectedState, selectedCity, gDid, god_last_rec))
    }
  }

  function getStyle(did) {
    if (did === filterId) {
      return {
        color: '#4096ff',
        borderColor: '#4096ff',
      };
    } else {
      return {
        borderColor: '#d9d9d9',
        color: '#000'
      };
    }
  }

  // const [currentPage, setCurrentPage] = useState(1);


  // useEffect(() => {
  //   dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, last_rec));
  // }, [storedLanguage, did, currentPage]);

  // useEffect(() => {

  //   if (!fstLoading && fstList) {
  //     setCurrentPageTemples([...currentPageTemples, ...fstList]);
  //   }

  // }, [fstLoading]);

  const nextTemplesList = async () => {
    setIsLoading(true);
    // Increment the current page

    if (filterId === null) {
      last_rec += records_per_page;
      dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, last_rec));
    } else {
      god_last_rec += records_per_page;
      dispatch(fetchFilterByStDt(storedLanguage, selectedState, selectedCity, did, god_last_rec))
    }
    // dispatch(fetchByStDt(storedLanguage, selectedState && selectedState.name, selectedCity, last_rec));
    setIsLoading(false);
  }

  const prevTemplesList = async () => {
    setIsLoading(true);
    // Increment the current page

    if (filterId === null) {
      last_rec -= records_per_page;
      dispatch(fetchByStDt(storedLanguage, selectedState, selectedCity, last_rec));
    } else {
      god_last_rec -= records_per_page;
      dispatch(fetchFilterByStDt(storedLanguage, selectedState, selectedCity, did, god_last_rec))
    }
    // dispatch(fetchByStDt(storedLanguage, selectedState && selectedState.name, selectedCity, last_rec));
    setIsLoading(false);
  }


  return (
    <>
      {
        filterId === null ? (
          stLoading || stList === null ? <Error /> : (
            <TemplesPage
              title={'Filtered Temples'}
              description={gList && gList.descr}
              tList={stList}
              tLoading={stLoading}
              setFilterId={setFilterId}
              godCtgryList={godCtgryList}
              filterTemplesList={filterTemplesList}
              godCtgryListLoading={godCtgryListLoading}
              pageTitle={'Filtered State Temples'}
              filterId={filterId}
              nextTemplesList={nextTemplesList}
              prevTemplesList={prevTemplesList}
              last_rec={last_rec}
              temple={gList && gList.descr}
              image={deftemples}
              selectedCity={selectedCity}
              selectedState={selectedState}
            />
          )
        ) : (
          fstLoading ? <Error /> : (
            <TemplesPage
              title={'Filtered Temples'}
              description={gList && gList.descr}
              tList={fstList}
              tLoading={fstLoading}
              setFilterId={setFilterId}
              godCtgryList={godCtgryList}
              filterTemplesList={filterTemplesList}
              godCtgryListLoading={godCtgryListLoading}
              pageTitle={'Filtered Temples'}
              filterId={filterId}
              nextTemplesList={nextTemplesList}
              prevTemplesList={prevTemplesList}
              last_rec={last_rec}
              temple={gList && gList.descr}
              selectedCity={selectedCity}
              selectedState={selectedState}
            />
          )
        )
      }
    </>
  )
}

export default StateFilter;
