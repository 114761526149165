import {
    Button, Form, Input, Typography, Dropdown, Space, message,
    Upload,
    Modal,
    Image,
    Col,
    Row,
    Select,
    DatePicker,
} from 'antd';
import React, { useEffect, useState } from 'react';
import "../../addTemple.css"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { editResource, fetchNotificationDetails, fetchRegionalNotifi, fetchResource, fetchResourceDetails, postResource, postTemples } from '../../../../redux/actions/acions';

import { useTranslation } from 'react-i18next';
import aum from '../../../../assets/icons/aum.png'
import ganesh from '../../../../assets/icons/vinayaka.png'
import durga from '../../../../assets/icons/durga.png'
import vishnu from '../../../../assets/icons/narayana.png'
import lakshmi from '../../../../assets/icons/lakshmi.png'
import rama from '../../../../assets/icons/rama.png'
import shiva from '../../../../assets/icons/eeswara.png'
import krishna from '../../../../assets/icons/krishna.png'
import kartikeya from '../../../../assets/icons/kumara.png'
import hanuma from '../../../../assets/icons/hanuma.png'
import brahma from '../../../../assets/icons/bramha.png'
import saraswati from '../../../../assets/icons/saraswathi.png'
import ayyappa from '../../../../assets/icons/ayyappa.png'
import saibaba from '../../../../assets/icons/saibaba.png'
import nrusimha from '../../../../assets/icons/nrusimha.png'
import surya from '../../../../assets/icons/surya.jpg'
import budda from '../../../../assets/icons/budda.png'
import { Option } from 'antd/es/mentions';
import { getNotificationDetailsList} from '../../../../redux/selectors/selectors';
import i18next from 'i18next';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import AP_LANGUAGES from "../../../common/appLangs"

const normFile = (e) => {
    
    if (Array.isArray(e)) {
        return e;
    }
    return e.fileList;
};

const EditEvent = () => {
    const { TextArea } = Input;
    const { t } = useTranslation();
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 9 });
    const { nid } = useParams()
    const [selectedDeity, setSelectedDeity] = useState(null);

    const dispatch = useDispatch()
    const [form] = Form.useForm();

    const onFinish = (values) => {
        const data = {
            "title": values.title,
            "author": values.author,
            "avatar": 1,
            "img_path": values.img_path,
            "src_url": values.src_url,
            "txt": values.txt,
            "res_type": values.res_type,
            "lang": values.lang,
            "publish_date": values.publish_date,
            "tag1": values.tag1,
            "tag2": values.tag1,
            "ctgry": 'God',
            "src": values.src
        }
        dispatch(editResource(nid, data))
    };

    const storedLanguage = localStorage.getItem('lng') || 5;
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    const handleChange = (value) => {
        localStorage.setItem('lng', value);
        setSelectedLanguage(value);
        // Make API request here with the selected language
    };

    useEffect(() => {
        i18next.changeLanguage(localStorage.getItem('i18nextLng'))
        //dispatch(fetchContributions(0, failure));
    }, [])

    const deityImages = {
        0: aum,
        1: ganesh,
        2: vishnu,
        3: lakshmi,
        4: durga,
        5: rama,
        6: shiva,
        7: krishna,
        8: kartikeya,
        9: hanuma,
        10: brahma,
        11: saraswati,
        12: ayyappa,
        13: saibaba,
        14: nrusimha,
        15: surya,
        16: budda,
    };

    const [showAllImages, setShowAllImages] = useState(false);

    const handleImageClick = () => {
        setShowAllImages(true);
    };

    const handleDeityImageClick = (did) => {
        setShowAllImages(false);
        //dispatch(postResource({ did: did, }));
    };


    const handleCloseModal = () => {
        setShowAllImages(false);
    };

    const [messageApi, contextHolder] = message.useMessage();
    const success = () => {
        showToast('success', t('label_editresource_success'))
        // messageApi.open({
        //     type: 'success',
        //     content: 'SUCCESSFULLY EDITED',
        // });
        form.resetFields()
    };

    const failure = (msg) => {
        showToast('fail', t('label_editresource_failure'))
        // messageApi.open({
        //     type: 'failed',
        //     content: msg,
        // });
    };
    const { tDetails } = useSelector(state => ({
        tDetails: getNotificationDetailsList(state),
    }))
    

    useEffect(() => {
        dispatch(fetchNotificationDetails(nid, storedLanguage));
    }, [storedLanguage, dispatch]);


    useEffect(() => {
        form.setFieldsValue(tDetails);
    }, [tDetails])

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div class='mainsec'>
                                    <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_add_edit_notif')}</span>
                </div>
                <div></div>
            </div>
            <CustomToastContainer/>
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                &nbsp; <span className="bc-active-link" onClick={handleGoBack}>{t('label_manage_notification')}</span> -
                &nbsp; <span className="bc-link">{t("label_add_edit_notif")}</span>
            </div>
            <div class="bg-details-highlight">
                <div class=" details-headertxt container">
                    <div class="header_txt">{tDetails&&tDetails.subject} </div>
                    <div class="d-flex flex-row header_star mr-5">
                        <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                        </span>
                    </div>
                </div>
            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    {/* <div className='details-page'> */}
                    {/* <Link to={'/'}><Typography.Text>Back</Typography.Text></Link> */}
                    <Form form={form} name="nest-messages" onFinish={onFinish}
                        initialValues={tDetails}
                        labelCol={{
                            span: 7,
                        }}
                        wrapperCol={{
                            span: 9,
                        }}
                    >
                        <div class="float-left w-100" ><h5 class="card-text-title">{t("label_noti_details")} <span>| {t('label_name_details_source')}</span></h5></div>
                        {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t("label_enter_tmpl_det")}</Typography.Title> */}
                        <div class="active-details">
                            {/* <Row gutter={10}>
                            <Col span={16}> */}

                            <div class="tiles-designs" >
                                <Form.Item
                                    // labelCol={{
                                    //     span: 11,
                                    // }}
                                    // wrapperCol={{
                                    //     span: 18,
                                    // }}
                                    name='subject'
                                    label={<Typography.Title level={4}>{t('label_name')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder="Enter Name" />
                                </Form.Item>
                            </div>

                            {/* </Col> */}
                            {/* <Col span={8}>
              <div style={{ marginTop: '5px' }}>
                <Image
                  src={deityImages[selectedDeity]}
                  alt="Selected Deity Image"
                  onClick={handleImageClick}
                  style={{ cursor: 'pointer', width: '15%' }}
                  mask={false}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                />
                <Modal
                  // title="Select Deity Image"
                  visible={showAllImages}
                  onCancel={handleCloseModal}
                  footer={null}
                  mask={false}
                >
                  <div class="diag-header">Select Deity Image
                    <span class="diag-close"><i class="fa-solid fa-xmark" onClick={handleCloseModal}></i></span></div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {Object.keys(deityImages).map((deity) => (
                      <Image
                        key={deity}
                        src={deityImages[deity]}
                        alt="Deity Image"
                        style={{ width: '100px', height: '100px', margin: '5px', cursor: 'pointer' }}
                        onClick={() => handleDeityImageClick(deity)}
                      />
                    ))}
                  </div>
                </Modal>
              </div>
            </Col> */}
                            {/* </Row> */}
                            {/* <Col span={16}> */}
                            <div class="tiles-designs" >
                                <Form.Item
                                    // labelCol={{
                                    //   span: 11,
                                    // }}
                                    // wrapperCol={{
                                    //   span: 18,
                                    // }}
                                    name='author'
                                    label={<Typography.Title level={4}>{t('label_author_speaker')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('label_enter_author_speaker')} />
                                </Form.Item>
                            </div>
                            {/* </Col>
          <Col span={16}> */}
                            <div class="tiles-designs" >
                                <Form.Item
                                    // labelCol={{
                                    //   span: 11,
                                    // }}
                                    // wrapperCol={{
                                    //   span: 18,
                                    // }}
                                    name='src'
                                    label={<Typography.Title level={4}>{t('label_source_link')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input style={{ height: '30px' }} placeholder={t('label_enter_source_link')} />
                                </Form.Item>
                            </div>
                            {/* </Col> */}
                            <div class="tiles-designs" >
                                <Form.Item
                                    name='txt'
                                    label={<Typography.Title level={4}>{t('details_button')}</Typography.Title>}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} placeholder={t('label_enter_details')} />
                                </Form.Item>
                            </div>
                            {/* <div class="tiles-designs" >
            <Form.Item
              name='history'
              label={<Typography.Title level={4}>{t("label_history")}</Typography.Title>}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={4} style={{ marginTop: '6px' }} placeholder={t('Enter History')} />
            </Form.Item>
          </div> */}
                        </div>
                        {/* <Form.Item
          name='otherdeities'
          label={t('Other Diety in same temple')}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name='descr' label="Temple History">

          <Input.TextArea />

        </Form.Item> */}
                        <div class="float-left w-100" ><h5 class="card-text-title">{t('label_additi_det')} <span>| {t('label_ctgry_lang')}</span></h5></div>
                        {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)" }}>{t('Address/Location Details')}</Typography.Title> */}
                        <div class="active-details">
                            <Col span={24}>
                                <div class="tiles-designs" >
                                    <Form.Item
                                        name='res_type'
                                        label={<Typography.Title level={4}>{t('label_categ')}</Typography.Title>}
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t('label_select _category')}>
                                            <Option value="video">{t("label_video")}</Option>
                                            <Option value="liveTV">{t("label_livetv")}</Option>
                                            <Option value="books">{t("label_books")}</Option>
                                            <Option value="articles">{t("label_articles")}</Option>
                                            <Option value="mantras">{("label_man")}</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Col>
                            <div class="tiles-designs" >
                                <Form.Item
                                    name="lang"
                                    label={<Typography.Title level={4}>{t("label_lang")}</Typography.Title>}
                                    initialValue={selectedLanguage}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a language.',
                                        },
                                    ]}
                                >
                                    <Select options={AP_LANGUAGES} placeholder={t('label_lang')} />
                                </Form.Item>
                            </div>


                        </div>


                        {/* <Typography.Title level={4} style={{ color: "rgb(229 123 13)", marginTop: '20px' }}>{t('Add/Remove Photos')}</Typography.Title> */}
                        <div class="active-details">
                            {/* <div class="tiles-designs" >
                            <Form.Item
                                name="imgpath"
                                label={<Typography.Title level={4}>{t('Add Photo')}</Typography.Title>}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                extra="use png/jpg/jpeg format only"
                            >
                                <ImgCrop rotationSlider>
                                    <Upload
                                        name="logo"
                                        action="/upload.do"
                                        listType="picture"
                                        onChange={onFileChange}
                                    >
                                        <Button icon={<UploadOutlined />}>{t('Click to upload')}</Button>
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <ReactCrop
                                src="https://via.placeholder.com/150"
                                crop={crop}
                                onImageLoaded={onImageLoaded}
                                onChange={onCropChange}
                                onComplete={onCropComplete}
                            />

                        </div> */}

                            <button type="submit" class="button_move">{t("label_save")}<i class="fa-solid fa-circle-right animate__animated animate__heartBeat"></i></button>
                        </div>
                        {/* <Button className='profile-form-button' style={{ width: '25%' }} type="primary" htmlType="submit" >
      {t("label_save")}
    </Button> */}

                    </Form>
                </div>

            </div>

        </div>
        
    );
};
export default EditEvent;