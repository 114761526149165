import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Input, Popconfirm, Select, message } from 'antd';
import { getServiceRegDetailsList, getServiceRegDetailsListError, getServiceRegDetailsListLoading } from '../../../../redux/selectors/selectors';
import {
    cancelService,
    editServiceRegistrationAddr,
    editServiceRegistrationAmount,
    editServiceRegistrationBill,
    editServiceRegistrationName,
    editServiceRegistrationNotes,
    editServiceRegistrationPhone,
    editServiceRegistrationQty,
    editServiceRegistrationStatus,
    fetchServiceRegDetails
} from '../../../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import Error from '../error';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const statusOptions = [
    { label: 'INITIATED', value: 'INITIATED' },
    { label: 'BOOKED', value: 'BOOKED' },
];

const ServiceRegDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { sregid } = useParams();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const token = localStorage.getItem('token');
    const userid = localStorage.getItem('urole');
    const [fieldUpdated, setFieldUpdated] = useState('');

    const success = () => {
        showToast('success', t('label_newservreg_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', t('label_newservreg_failure'));
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getServiceRegDetailsList(state),
        rLoading: getServiceRegDetailsListLoading(state),
        rError: getServiceRegDetailsListError(state),
    }));

    useEffect(() => {
        dispatch(fetchServiceRegDetails(sregid));
    }, [storedLanguage, dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({});
    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));

    const handleCancelClick = () => {
        setButtonText('Edit');
        setEditable(false);

    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            setFormData({ ...rList });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleFieldChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        setFieldUpdated(field);
    };

    const updateDetails = (initialData) => {
        let apiCalls = [];
        let hasUpdates = false;
        // Compare each field's value with the initial data to check if it has changed
        if (formData.amount !== initialData.amount) {
            apiCalls.push(dispatch(editServiceRegistrationAmount(sregid, { amount: formData.amount })));
            hasUpdates = true;
        }
        if (formData.devotee !== initialData.devotee) {
            apiCalls.push(dispatch(editServiceRegistrationName(sregid, { devotee: formData.devotee })));
            hasUpdates = true;
        }
        if (formData.phone !== initialData.phone) {
            apiCalls.push(dispatch(editServiceRegistrationPhone(sregid, { phone: formData.phone })));
            hasUpdates = true;
        }
        if (formData.addr !== initialData.addr) {
            apiCalls.push(dispatch(editServiceRegistrationAddr(sregid, { addr: formData.addr })));
            hasUpdates = true;
        }
        if (formData.notes !== initialData.notes) {
            apiCalls.push(dispatch(editServiceRegistrationNotes(sregid, { notes: formData.notes })));
            hasUpdates = true;
        }
        if (formData.bill !== initialData.bill) {
            apiCalls.push(dispatch(editServiceRegistrationBill(sregid, { bill: formData.bill })));
            hasUpdates = true;
        }
        if (formData.status !== initialData.status) {
            apiCalls.push(dispatch(editServiceRegistrationStatus(sregid, { status: formData.status })));
            hasUpdates = true;
        }
        if (formData.qty !== initialData.qty) {
            apiCalls.push(dispatch(editServiceRegistrationQty(sregid, { qty: formData.qty })));
            hasUpdates = true;
        }
        // Execute the API calls if there are any changes
        if (hasUpdates) {
            Promise.all(apiCalls)
                .then(() => showToast('success', t('All updates successful')))
                .catch(() => showToast('fail', t('Some updates failed')));
        } else {
            showToast('fail', t('No field updated'));
        }
    };

    const handleLinkClick = () => {
        message.info('Please Login to use additional features');
    };

    function abortService(sregid) {
        dispatch(cancelService(sregid, success, failure));
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const downloadAsPDF = (serviceRegDetails) => {
        const doc = new jsPDF();
        doc.setFontSize(18);

        const rows = [
            { label: t('label_devotee_name'), value: serviceRegDetails.devotee },
            { label: t('label_addr'), value: serviceRegDetails.addr },
            { label: t('label_phone'), value: serviceRegDetails.phone },
            { label: t('label_date'), value: formatDate(serviceRegDetails.servdt) },
            { label: t('label_amount'), value: serviceRegDetails.amount },
            { label: t('label_status'), value: serviceRegDetails.status },
        ];

        const formattedRows = rows.map(item => [item.label, item.value]);

        doc.autoTable({
            startY: 20,
            body: formattedRows,
            theme: 'grid',
        });

        doc.save('Service-registration-details.pdf');
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_service_reg_det')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}
            
            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt'>
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <Link style={{ color: 'black' }} onClick={handleGoBack}><span className="bc-active-link">{t('label_services')}</span></Link> - <span className="bc-link">{t("label_service_reg_det")}</span>
            </div>
            <div class="bg-details-highlight">
            <div class=" details-headertxt-singleLine container">
                    <div class="header_txt"> {t('label_service_reg_det')}</div>
                    <div class="details-headertxt-singleLine-btns">
                        {/* Render the Save button only if in edit mode */}
                        {editable && (
                            <a   className="button_move"
                                onClick={() => updateDetails(rList)}
                                
                            >
                                {t("label_save")}
                            </a>
                        )}
                        &nbsp;

                        {token ? (
                            <a   className="button_move"
                                onClick={editable ? handleCancelClick : handleEditClick}>
                                {buttonText}
                            </a>
                        ) : (
                            <a   className="button_move"
                                onClick={handleLinkClick}>
                                {buttonText}
                            </a>
                        )}
                        &nbsp;

                        <button className="button_move" type="primary"  >
                            <Popconfirm
                                title="Are you sure you want to delete?"
                                onConfirm={() => abortService(rList.sregid)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Link >{t('label_cancel_service')}</Link>
                            </Popconfirm>
                        </button>
                        &nbsp;
                        <a   className="button_move" onClick={() => downloadAsPDF(rList)}>
                            {t("Download")}
                        </a>
                    </div>
                    

                </div>
            </div>
            <div className='container'>
                {rLoading ? (
                    <p>Loading...</p>
                ) : (
                    rList && (
                        <div className='horoscope-item'>
                            <div class="float-left w-100"><h5 class="card-text-title">{t("label_details")} <span>| {t('label_ctgry_link_src')}</span></h5></div>
                            <Form form={form}>
                                <div class="active-details">
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_devotee")}</Typography.Title>}
                                            name="devotee"
                                        >
                                            {editable ? (
                                                <Input
                                                    placeholder="Enter Devotee Name"
                                                    defaultValue={rList.devotee}
                                                    onChange={(e) => handleFieldChange('devotee', e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.devotee}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_addr")}</Typography.Title>}
                                            name="addr"
                                        >
                                            {editable ? (
                                                <Input.TextArea
                                                    placeholder="Enter Address"
                                                    defaultValue={rList.addr}
                                                    onChange={(e) => handleFieldChange('addr', e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.addr}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_phone")}</Typography.Title>}
                                            name="phone"
                                        >
                                            {editable ? (
                                                <Input
                                                    placeholder="Enter Phone"
                                                    defaultValue={rList.phone}
                                                    onChange={(e) => handleFieldChange('phone', e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.phone}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_quantity")}</Typography.Title>}
                                            name="qty"
                                        >
                                            {editable ? (
                                                <Input
                                                    placeholder="Enter Quantity"
                                                    defaultValue={rList.qty}
                                                    onChange={(e) => handleFieldChange('qty', e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.qty}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_date")}</Typography.Title>}
                                            name="servdt"
                                        >
                                            {editable ? (
                                                <DatePicker
                                                    style={{ height: '30px' }}
                                                    defaultValue={moment(rList && rList.servdt)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>
                                                    {formatDate(rList && rList.servdt)}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_amount")}</Typography.Title>}
                                            name="amount"
                                        >
                                            {editable ? (
                                                <Input
                                                    placeholder="Enter Amount"
                                                    defaultValue={rList.amount}
                                                    onChange={(e) => handleFieldChange('amount', e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.amount}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_notes")}</Typography.Title>}
                                            name="notes"
                                        >
                                            {editable ? (
                                                <Input
                                                    placeholder="Enter Notes"
                                                    defaultValue={rList.notes}
                                                    onChange={(e) => handleFieldChange('notes', e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.notes}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_bill")}</Typography.Title>}
                                            name="bill"
                                        >
                                            {editable ? (
                                                <Input
                                                    placeholder="Enter Bill"
                                                    defaultValue={rList.bill}
                                                    onChange={(e) => handleFieldChange('bill', e.target.value)}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.bill}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div class="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_status")}</Typography.Title>}
                                            name="status"
                                        >
                                            {editable ? (
                                                <Select
                                                    placeholder="Select Status"
                                                    defaultValue={rList.status}
                                                    onChange={(value) => handleFieldChange('status', value)}
                                                >
                                                    {statusOptions.map(option => (
                                                        <Select.Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.status}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )
                )}
            </div>
            <Footer />
        </div>
    );
};

export default ServiceRegDetails;
