import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Result, Row, Skeleton, message } from 'antd';
import { getDailyHoroscopeList, getDailyHoroscopeLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../../../redux/selectors/selectors';
import { fetchDailyHoroscope, fetchRegionalNotifi } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import Error from '../error';
import { EditOutlined } from '@ant-design/icons';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import Footer from '../footer/footer';

const Events = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;

    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();

    const failure = (msg) => {
        showToast('fail', t('label_horodetails_failure'))
        // messageApi.error(msg); // You can use 'error' directly
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getRegionalNotifiList(state),
        rLoading: getRegionalNotifiLoading(state),
        rError: getRegionalNotifiError(state),
    }));


    useEffect(() => {
        dispatch(fetchRegionalNotifi(storedLanguage, 0));
    }, [storedLanguage, dispatch]);

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_events')}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link  ' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">Home</span></Link> -
                {/* <Link style={{ color: 'black' }} to='/templeCtgry'><span className="bc-link"> Temples</span></Link> - */}
                {/* <span className="bc-link" onClick={handleGoBack}> {title}</span> - */}
                &nbsp;<span className="bc-link">{t("label_events")}</span>
            </div>
            <div class="bg-details-highlight" >
                <div class=" details-headertxt container">
                    <div class="header_txt"> {t("label_events")}</div>
                    <div class="">

                    </div>
                    <div class="d-flex flex-row header_star mr-5">
                        <span class="f18 mb-2 colorHeadingviolet fw-7 events-icon">
                        </span>
                    </div>
                </div>
            </div>
            <div className="mainsec-block-addtemple-detailspage">
                <div className='container'>

                    {rLoading ? (
                        <Error />
                    ) : Array.isArray(rList) ? (
                        rList.map((notificationList) => {
                            return (
                                <>



                                    <div key={notificationList.nid} className="tiles-designs-events" >
                                        <div class="d-flex flex-space-between">
                                            <Link to={`/notifiDetails/${notificationList.nid}`} className="notification-link">
                                                <div class="f1 events-block-link-title" >
                                                    <i class="fa-solid fa-calendar-week mr-3"></i>
                                                    <span class="f1" >{notificationList.subject}</span>
                                                    {/* <Link to={`/editEvent/${notificationList.nid}`} >
                                    <EditOutlined className="edit-icon" title="Edit" />
                                    </Link> */}
                                                </div>
                                            </Link>
                                            <div class="d-flex">
                                                {/* <div className="detail-but ml-3">
                                                    Goto Temple Page
                                                </div> */}
                                                <a
                                                    className="share-button"
                                                    style={{ color: "#c15506", marginTop: "10px" }}
                                                    onClick={() => {
                                                        if (navigator.share) {
                                                            navigator.share({
                                                                title: "Know " + notificationList.subject,
                                                                text: "Know " + notificationList.subject,
                                                                url: `https://www.templeswiki.com/eventDetails/${notificationList.nid}`
                                                            })
                                                                .then(() => console.warn("Share successful."))
                                                                .catch((error) => console.error("Error sharing:", error));
                                                        } else {
                                                            // Fallback for browsers that do not support the Web Share API
                                                            console.error("Web Share API not supported.");
                                                        }
                                                    }}
                                                >
                                                    <i title="Share" className="fa-solid fa-share-nodes"></i>
                                                </a>

                                            </div></div>

                                        <span class="f1 events-block-link-value">{notificationList.txt}</span>

                                    </div >



                                    {/* <span class="f1 events-block-links-title">{notificationList.subject}</span>
                                <Link to={`/editEvent/${notificationList.nid}`} >
                                    <EditOutlined className="edit-icon" title="Edit" />
                                </Link>
                                <span class="f1 events-block-links">{notificationList.txt}</span> */}

                                </>
                            )
                        })
                    ) : (
                        <Result
                            status="404"
                            title={t("label_nodata_found")}
                            subTitle={t("label_no_events_msg")}
                        />
                    )}
                </div>
                <Footer />
            </div>
        </div >
    );
};

export default Events;
