import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Col, Row, message } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import TemplesPage from '../../common/TemplesPage';
import {
  getFavouritesError,
  getFavouritesList,
  getFavouritesLoading,
  getGodNames,
  getGodNamesError,
  getGodNamesLoading,
  getTempleTxtError,
  getTempleTxtList,
  getTempleTxtLoading,
} from '../../../redux/selectors/selectors';
import {
  fetchFavourites,
  fetchGodNames,
  fetchTempleTxt,
  postFavourites,
  removeFavourites,
  retrieveGodNames,
} from '../../../redux/actions/acions';
import Error from './error';
import Footer from './footer/footer';

function SearchResults() {
  const { t } = useTranslation();
  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const records_per_page = 12;
  const [currentPage, setCurrentPage] = useState(0);
  const [filterId, setFilterId] = useState(null);
  const { searchText } = useParams();
  const dispatch = useDispatch();
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [isLoading, setIsLoading] = useState(false);
  const localEnv = false;

  const [messageApi, contextHolder] = message.useMessage();

  const { txList, txLoading, txError, godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({
    txList: getTempleTxtList(state),
    txLoading: getTempleTxtLoading(state),
    txError: getTempleTxtError(state),
    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }));

  useEffect(() => {
    setCurrentPage(0); // Reset to the first page on searchText change
  }, [searchText]);

  useEffect(() => {
    dispatch(fetchTempleTxt(storedLanguage, searchText, currentPage * records_per_page));
  }, [storedLanguage, searchText, currentPage]);


  useEffect(() => {
    dispatch(localEnv ? fetchGodNames(failure) : retrieveGodNames(storedLanguage));
  }, [storedLanguage]);


  const failure = (msg) => {
    messageApi.open({
      type: 'error',
      content: 'Please Login to see additional features',
    });
  };


  function nextTemplesList() {
    setIsLoading(true);
    setCurrentPage(prevPage => prevPage + 1);
  }

  function prevTemplesList() {
    setCurrentPage(prevPage => (prevPage > 0 ? prevPage - 1 : 0));
  }

  return (
    <>
      {contextHolder}    
        <TemplesPage
          title={searchText}
          description={'This is temple you have searched'}
          tLoading={txLoading}
          tList={txList}
          pageTitle={searchText}
          godCtgryList={godCtgryList}
          godCtgryListLoading={godCtgryListLoading}
          nextTemplesList={nextTemplesList}
          prevTemplesList={prevTemplesList}
        />
    </>
  );
}

export default SearchResults;
