import React, { useEffect, useState } from 'react'
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Footer from '../footer/footer';
import videos from '../../../../assets/images/video.jpg'
import audios from '../../../../assets/images/audio.jpg'
import mantras from '../../../../assets/images/mantras.jpg'
import articles from '../../../../assets/images/article.jpg'
import showToast from '../../../../utils/showToast';
import { useNavigate, useParams } from 'react-router-dom';
import CustomToastContainer from '../../../common/CustomToastContainer';
import { fetchServicesByUser } from '../../../../redux/actions/acions';
import { getServicesByUserList, getServicesByUserListError, getServicesByUserListLoading } from '../../../../redux/selectors/selectors';
import cholaTemples from '../../../../assets/images/darshan.jpg';
import { useDispatch, useSelector } from 'react-redux';

const TempleAdminLinks = () => {
    const { t } = useTranslation();
    const tid = localStorage.getItem('orgid');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const { tname } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { nList, nLoading, nError } = useSelector((state) => ({
        nList: getServicesByUserList(state),
        nLoading: getServicesByUserListLoading(state),
        nError: getServicesByUserListError(state),
    }));

    useEffect(() => {
        // Initial API request on component mount
        dispatch(fetchServicesByUser(tid, 0));
    }, [tid, dispatch]);

    return (
        <>
            
            <div class="mainsec">
                <div class=" mob-nav fluid-container page-title justify-content-between">
                    <div class="d-flex align-items-center left-sec-mob-nav">
                        <Link to='/' ><i class="fa-solid fa-angle-left"></i></Link>
                        <span class="bc-link">{t('label_library')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>

                    <Link style={{ color: 'black' }} to='/'><span class="bc-active-link">{t('Home')}</span></Link> -

                    <span class="bc-link">{t("lable_view_prof")}</span></div>
                <div class="mainsec-block">
                    <div className="container">
                        <div class="container">
                            <h5 class="card-text-title">{t("label_templ_adm_links")}
                            </h5>
                        </div>
                        <div className="tiles-main">
                        <Link to='/templeDashboard'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={'https://scholarlykitchen.sspnet.org/wp-content/uploads/2018/07/iStock-506432952.jpg'} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_temple_users")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/donations'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={'https://scholarlykitchen.sspnet.org/wp-content/uploads/2018/07/iStock-506432952.jpg'} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_donations")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/addDonations'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={videos} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_add_donations")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/expenses'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={articles} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_expenses")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/addExpenses'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_add_expense")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/createService'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={audios} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_create_service")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/adminService'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_all_services")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/templeservicereglist'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_servicereg_list")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                            <Link to='/donationsByMonth'>
                                <div class="tiles-design-temple-cat animate__animated animate__flipInX">
                                    <div class="title-img-bg">
                                        <img class="card-img-temple-cat" alt="temples" src={mantras} />
                                    </div>
                                    <div class="tile-text-temple-cat">
                                        <span>{t("label_donations_month_rep")}</span> <span>{("label_temples_list")}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="mainsec-block">
                <div className="container">
                    <div className="container">
                        <h5 className="card-text-title">{t("label_services")} <span>| {t("label_services_like_msg")}</span></h5>
                    </div>
                    <div className="tiles-main">
                        {nList && nList.length > 0 ? (
                            nList.map((temple, index) => (
                                // Updated link to pass both `servid` and `tname` to ServiceDetails
                                <Link to={`/ServiceDetails/${temple.servid}/${tname}`} key={index}>
                                    <div className="tiles-design-temple-cat animate__animated animate__flipInX">
                                        <div className="title-img-bg">
                                            <img className="card-img-temple-cat" alt="temples" src={cholaTemples} />
                                            <div className="hover-links">
                                                <a href="/freedarshan">{temple.servtype}</a>
                                            </div>
                                        </div>
                                        <div className="tile-text-temple-cat">
                                            <span>{temple.ctgry}</span> <span>{("label_temples_list")}</span>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p>No services found</p>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
                </div>
            </div>
        </>
    )

}

export default TempleAdminLinks;