import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message, Table, Input,Form, Select, Col, Switch, Typography } from 'antd';
import { getAppUsersList, getAppUsersListError, getAppUsersListLoading, getTempleComplaintsList, getTempleComplaintsListError, getTempleComplaintsLoading } from '../../redux/selectors/selectors';
import { changeComplaintStatus, editServiceRegistrationStatus, fetchAppUsers, fetchTempleComplaints } from '../../redux/actions/acions';
import { useTranslation } from 'react-i18next';
import CustomToastContainer from '../common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';
import showToast from '../../utils/showToast';

const { Search } = Input;
const { Option } = Select;
var last_rec = 0
const TempleComplaints = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { reqid } = useParams()
    const records_per_page = 12;
    const [messageApi, contextHolder] = message.useMessage();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedState, setSelectedState] = useState();
    const [selectedRole, setSelectedRole] = useState();


    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(12);

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getTempleComplaintsList(state),
        rLoading: getTempleComplaintsLoading(state),
        rError: getTempleComplaintsListError(state),
    }));

    console.log(rList)

   

    useEffect(() => {
        dispatch(fetchTempleComplaints(last_rec, () => {
            console.warn('Fetch temple complaints success callback');
        }));
    }, [dispatch]);

    const nextTemplesList = async () => {
        last_rec = last_rec + records_per_page;
        // did == filterId ? god_last_rec = god_last_rec + records_per_page : god_last_rec = 0 + records_per_page;
        dispatch(fetchTempleComplaints(last_rec));
        // setIsLoading(false);
    }

    function prevTemplesList() {
        last_rec = last_rec - records_per_page;
        last_rec = (last_rec < 0) ? 0 : last_rec;
        // god_last_rec = god_last_rec - records_per_page;
        // god_last_rec = (god_last_rec < 0) ? 0 : god_last_rec;
        dispatch(fetchTempleComplaints(last_rec))
    };


    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    const handleReset = () => {
        setSelectedState(undefined);
        setSelectedRole(undefined);
    };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const filteredData = rList && rList.filter(item => {
        const isStateMatch = selectedState === undefined || String(item.active) === selectedState;
        const isRoleMatch = selectedRole === undefined || item.urole === selectedRole;
        return (item.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.urole.toLowerCase().includes(searchTerm.toLowerCase())) && isStateMatch && isRoleMatch;
    });

    // Handle case when filteredData is null or empty
    const modifiedData = (filteredData && filteredData.length > 0)
        ? filteredData.map((item, index) => ({ ...item, "S.No": index + 1 }))
        : [];

    const startIndex = (currentPage - 1) * pageSize;
    const paginatedData = modifiedData.slice(startIndex, startIndex + pageSize);
    const [isActive, setIsActive] = useState(false);
    const [editable, setEditable] = useState(false);

    const columns = [
        {
            title: <span reqid={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>ID</span>,
            dataIndex: 'reqid',
            key: 'reqid',
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Temple</span>,
            dataIndex: 'tid',
            key: 'tid',
            render: (text) => (
                <a target='_blank' href={`/templeDetails/${text}`} style={{ color: '#007bff', textDecoration: 'underline' }}>
                    {text}
                </a>
            ),
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Name</span>,
            dataIndex: 'fullname',
            key: 'fullname',
        },      
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Request Type</span>,
            dataIndex: 'reqtype',
            key: 'reqtype',
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Query</span>,
            dataIndex: 'query',
            key: 'query',
        },
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Status</span>,
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                <Switch
                    onChange={(checked) => handleSelectChange(checked, record)}
                />
            ),
        },        
        {
            title: <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#007bff' }}>Created On</span>,
            dataIndex: 'crdt',
            key: 'crdt',
            render: (text) => {
                const formattedDate = new Date(text).toISOString().split('T')[0];
                return <span>{formattedDate}</span>;
            },
        },
        
    ];
    
    const handleSelectChange = (checked, record) => {
        const newStatus = checked ? "CLOSED" : "OPEN";
        dispatch(changeComplaintStatus(record.reqid, { status: newStatus }, success, failure));
        setIsActive(checked);
    };

    const success = () => {
        showToast('success', t('label_complnt_status_success'));
        Form.resetFields();
    };

    const failure = (msg) => {
        showToast('error', t('label_complnt_status_failure'));
    };
    

    // Handle Next and Prev button clicks
    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (startIndex + pageSize < modifiedData.length) {
            setCurrentPage(prev => prev + 1);
        }
    };

    return (
        <>
            <div className='mainsec'>
                {contextHolder}
                
                <div className="mob-nav fluid-container page-title justify-content-between">
                    <div className="d-flex align-items-center left-sec-mob-nav">
                        <Link onClick={handleGoBack} ><i className="fa-solid fa-angle-left"></i></Link>
                        <span className="bc-link">{t('label_temple_complaints')}</span>
                    </div>
                    <div></div>
                </div>
                <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
                    <Link style={{ color: 'black' }} to='/'>
                        <span className="bc-active-link">Home</span>
                    </Link> - <span className="bc-link">{t("label_temple_complaints")}</span>
                </div>
                <div className="mainsec-block">
                    <div className="container">
                        <h5 className="card-text-title">{t("label_temple_complaints")}</h5>
                    </div>
                    <div className='container'>
                        <Table dataSource={paginatedData} columns={columns} bordered style={{ border: '2px solid black', borderCollapse: 'collapse' }} pagination={false} />
                        <div className="next-row">
                            {rList && rList.length > 0 ? (
                                <Col>
                                    <a
                                        className="button_move"
                                        onClick={last_rec === 0 ? null : prevTemplesList}
                                        disabled={last_rec === 0}
                                    >
                                        Prev
                                    </a>
                                </Col>
                            ) : (
                                ''
                            )}
                            {rList && rList.length > 0 ? (
                                <Col>
                                    <a
                                        className="button_move"
                                        onClick={rList.length < records_per_page ? null : nextTemplesList}
                                        disabled={rList.length < records_per_page}
                                    >
                                        Next
                                    </a>
                                </Col>
                            ) : (
                                ''
                            )}
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default TempleComplaints;
