import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Image, Input, Popconfirm, Result, Row, Select, Skeleton, message } from 'antd';
import { getDailyHoroscopeError, getDailyHoroscopeList, getDailyHoroscopeLoading, getDonationDetails, getDonationDetailsError, getDonationDetailsLoading, getMyRequestsDetailsError, getMyRequestsDetailsList, getMyRequestsDetailsLoading, getNotificationDetailsError, getNotificationDetailsList, getNotificationDetailsLoading, getPriestDetailsError, getPriestDetailsList, getPriestDetailsLoading, getRegionalNotifiError, getRegionalNotifiList, getRegionalNotifiLoading } from '../../redux/selectors/selectors';
import { deleteDonation, fetchDailyHoroscope, fetchDonationsDetails, fetchNotificationDetails, fetchPriestDetails, fetchPriestReqDetails, fetchRegionalNotifi, updateDonationAmount, updateDonationBill, updateDonationNotes, updateDonationPayment, updatePriestDegree, updatePriestDetails, updatePriestQuery, updatePriestReqStatus, updatePriestResolution, updatePriestSpclty, updatedonationType } from '../../redux/actions/acions';
import deftemples from '../../assets/images/templeicon.jpg'
import { useTranslation } from 'react-i18next';
import { getImageAPI } from "../../http-common";
import Error from '../pages/Homepage/error';
import { Option } from 'antd/es/mentions';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';
import showToast from '../../utils/showToast';
import CustomToastContainer from '../../components/common/CustomToastContainer';
import Footer from '../pages/Homepage/footer/footer';

const MyReqDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { reqid } = useParams()
    const dispatch = useDispatch();
    const urole = localStorage.getItem('urole')
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(null);
    const [dontype, setDontype] = useState(null);
    const [resolution, setResolution] = useState(null);
    const [bill, setBill] = useState(null);
    const [payment, setPayment] = useState(null);
    const [query, setQuery] = useState(null);

    const success = () => {
        showToast('success', t('label_req_query_update_success'));
    };

    const failure = (msg) => {
        showToast('fail', t(`label_req_query_update_failure`))
    };

    const { rList, rLoading, rError } = useSelector(state => ({
        rList: getMyRequestsDetailsList(state),
        rLoading: getMyRequestsDetailsLoading(state),
        rError: getMyRequestsDetailsError(state),
    }));

    useEffect(() => {
        dispatch(fetchPriestReqDetails(reqid));
    }, [dispatch]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const [formData, setFormData] = useState({
        // fullname: sDetails.fullname || '',
        // gender: sDetails.gender || '',
        // lang: sDetails.lang || '',
    });

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const [form] = Form.useForm();

    const handleCancelClick = () => {
        setButtonText('Edit');
        setEditable(false);
        navigate(-1);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            setFormData({ ...formData, amount: rList && rList.amount });
            setFormData({ ...formData, otherdeities: rList && rList.otherdeities });
        } else {
            setButtonText('Edit');
        }
        setEditable(!editable);
    };

    const handleSaveClick = () => {
        dispatch(updatePriestQuery(reqid, { query: formData.query }, success, failure));
        setButtonText('Edit');
        setEditable(false);
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, query: e.target.value });
    };

    const handleSelect = (value) => {
        dispatch(updatePriestSpclty(reqid, { splty: `${value}` }));
    };

    const handleResolution = (e) => {
        setResolution(e.target.value)
    };

    const handleStatus = (e) => {
        dispatch(updatePriestReqStatus(reqid, { status: query }, success));
    };


    const updateQuery = () => {
        dispatch(updatePriestQuery(reqid, { query: query }, success));
    };

    const updateResolution = () => {
        dispatch(updatePriestResolution(reqid, { resolution: resolution }, success));
    };

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('My Request Details')}</span>
                </div>
                <div></div>
            </div>

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt' >
                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-active-link" onClick={handleGoBack}>{t('label_my_requests')}</span> - <span className="bc-link">{t("label_my_req_details")}</span>
            </div>
            <div class="bg-details-highlight">
            <div class=" details-headertxt-singleLine container">
                    <div className="header_txt">
                        <span className="highlighted-text">
                            {rList && (
                                rList.reqtype === 'J'
                                    ? t('label_jathakam')
                                    : rList.reqtype === 'V'
                                        ? t('label_vratham')
                                        : rList.reqtype === null
                                            ? t('label_notchosen')
                                            : ''
                            )}
                        </span>
                    </div>
                    <div>
                        {editable && (
                            <a
                                 
                                className="button_move"
                                style={{ marginRight: '10px' }}
                                onClick={handleSaveClick}
                            >
                                {t("label_save")}
                            </a>
                        )}
                        <a
                             
                            className="button_move"
                            onClick={(e) => {
                                e.preventDefault();
                                if (rList && rList.status === 'Closed') {
                                    showToast('error', 'Request is Closed, Cannot Edit!');
                                    return;
                                }
                                editable ? handleCancelClick() : handleEditClick();
                            }}
                        >
                            {buttonText}
                        </a>
                    </div>
                </div>

            </div>
            <div class="mainsec-block-addtemple-detailspage">
                <div className='container'>
                    {rList && rList ? (
                        <Form
                            form={form}
                            name='basic'
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 10 }}
                        // onFinish={handleSubmit}
                        >
                            <div className='horoscope-item'>
                                <div className="float-left w-100 mt-4">
                                    <h5 className="card-text-title">{t('label_my_req_details')} <span>| {t('label_priest_req_details')}</span></h5>
                                </div>
                                <div className="active-details">
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_query")}</Typography.Title>}
                                            name="query"
                                        >
                                            {editable ? (
                                                <TextArea
                                                    level={10}
                                                    defaultValue={rList.query}
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                <Typography.Text level={5} className='typ'>{rList.query}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_categ")}</Typography.Title>}
                                            name="ctgry"
                                        >
                                            <Typography.Text level={5} className='typ'>
                                                {rList ? (
                                                    (() => {
                                                        switch (rList.ctgry) {
                                                            case '1':
                                                                return t('label_horo');
                                                            case '2':
                                                                return t('label_muhurtham');
                                                            case '3':
                                                                return t('label_puja');
                                                            case '4':
                                                                return t('label_homam');
                                                            default:
                                                                return t('label_notchosen');
                                                        }
                                                    })()
                                                ) : (
                                                    t('label_notchosen')
                                                )}
                                            </Typography.Text>
                                        </Form.Item>
                                    </div>


                                    <div className="tiles-designs">
                                        <Col>
                                            <Form.Item
                                                label={<Typography.Title level={4}>{t("label_req_type")}</Typography.Title>}
                                                name="reqtype"
                                            >
                                                <Typography.Text level={5} className='typ'>
                                                    {rList ? (
                                                        (() => {
                                                            switch (rList.reqtype) {
                                                                case 'J':
                                                                    return t('label_jathakam');
                                                                case 'V':
                                                                    return t('label_vratham');
                                                                default:
                                                                    return t('label_notchosen');
                                                            }
                                                        })()
                                                    ) : (
                                                        t('label_notchosen')
                                                    )}
                                                </Typography.Text>
                                            </Form.Item>
                                        </Col>
                                    </div>

                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_status")}</Typography.Title>}
                                            name="status"
                                        >
                                            <Typography.Text level={5} className='typ'>
                                                {rList ? (
                                                    (() => {
                                                        switch (rList.status) {
                                                            case 'Open':
                                                                return t('label_see_them');
                                                            case 'Closed':
                                                                return t('label_resolved');
                                                            default:
                                                                return t('label_notchosen');
                                                        }
                                                    })()
                                                ) : (
                                                    t('label_notchosen')
                                                )}
                                            </Typography.Text>
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_date")}</Typography.Title>}
                                            name="date"
                                        >
                                            <Typography.Text level={5} className='typ'>{rList.ludt}</Typography.Text>
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_resolution")}</Typography.Title>}
                                            name="resolution"
                                        >
                                            <Typography.Text level={5} className='typ'>{rList.resolution}</Typography.Text>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    ) : (
                        <Result
                            status="404"
                            title={t("label_nodata_found")}
                            subTitle={t("label_no_reqs_msg")}
                        />
                    )}
                </div>
                <Footer />
            </div>

        </div >
    );
};

export default MyReqDetails;
