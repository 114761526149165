import React, { useEffect, useState } from 'react'

import { message, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchTempleGroups, fetchTemplesByGroup } from '../../../../redux/actions/acions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import showToast from '../../../../utils/showToast';
import { getTempleGroupError, getTempleGroupList, getTempleGroupLoading } from '../../../../redux/selectors/selectors';
import Footer from '../footer/footer';
import statesData from '../../states.json';
import deftemples from '../../../../assets/images/templeicon.jpg'
import india from '../../../../assets/images/india.png';
import ap from '../../../../assets/images/ap.jpg';
import ts from '../../../../assets/images/ts.jpg';
import arp from '../../../../assets/images/arp.jpg';
import assam from '../../../../assets/images/assam.jpg';
import bihar from '../../../../assets/images/bihar.jpg';
import chgr from '../../../../assets/images/chgr.jpg';
import tripura from '../../../../assets/images/tripura.jpg';
import mizoram from '../../../../assets/images/mizoram.jpg';
import delhi from '../../../../assets/images/delhi.jpg';
import cdgr from '../../../../assets/images/cdgr.jpg';
import dnh from '../../../../assets/images/dnh.jpg';
import dnd from '../../../../assets/images/dnd.jpg';
import ann from '../../../../assets/images/ann.jpg';
import Search from 'antd/es/transfer/search';

const TempleInIndia = () => {
    const { t } = useTranslation();
    const [states, setStates] = useState([]);
    const {gid} = useParams();
    const [filteredStates, setFilteredStates] = useState([]);

   

    const stateImages = {
        "Andhra Pradesh": ap,
        "Arunachal Pradesh": arp,
        "Assam": assam,
        "Bihar": bihar,
        "Chhattisgarh": chgr,
        "Goa": "https://tse3.mm.bing.net/th?id=OIP.85fhLOJjeuF8R54zpfs0ywAAAA&pid=Api&P=0&h=180",
        "Gujarat": "https://tse4.mm.bing.net/th?id=OIP.sm37Uepl73vTOMGHDFEGkwHaFA&pid=Api&P=0&h=180",
        "Haryana": "https://tse4.mm.bing.net/th?id=OIP.BH_YQdMfNLr8_zefcKO-AwHaHf&pid=Api&P=0&h=180",
        "Himachal Pradesh": "https://tse1.mm.bing.net/th?id=OIP.jiJ0qNJ1ndDJy3BGyku47AHaE7&pid=Api&P=0&h=180",
        "Jharkhand": "https://tse2.mm.bing.net/th?id=OIP.JtqHJy4ICz9Vsm5vQZdNDwHaFj&pid=Api&P=0&h=180",
        "Karnataka": "https://www.infoandopinion.com/wp-content/uploads/2019/09/Karnataka-Districts-768x1152.jpg",
        "Kerala": "https://tse3.mm.bing.net/th?id=OIP.rNHmdGksCX4mPoKHtPQFegHaLl&pid=Api&P=0&h=180",
        "Madhya Pradesh": "https://tse3.mm.bing.net/th?id=OIP.f54J2w0j0UPAF6BhVCyvdgHaE3&pid=Api&P=0&h=180",
        "Maharashtra": "https://tse3.mm.bing.net/th?id=OIP.b8ik9QHjO9vb7HC0zZmf4QHaGp&pid=Api&P=0&h=180",
        "Manipur": "https://tse1.mm.bing.net/th?id=OIP.OefjOn8CK3Zlk8sWvtyYjQHaEn&pid=Api&P=0&h=180",
        "Meghalaya": "https://tse4.mm.bing.net/th?id=OIP.Gl-toyXrKcgTtu1NcrLn-AHaDp&pid=Api&P=0&h=180",
        "Mizoram": mizoram,
        "Nagaland": "https://tse3.mm.bing.net/th?id=OIP.T2YQyVULiH8TfjJCIpleJAHaGs&pid=Api&P=0&h=180",
        "Odisha": "https://tse4.mm.bing.net/th?id=OIP.m7gtYS48Du3XdVWR69KD7gHaGg&pid=Api&P=0&h=180",
        "Punjab": "https://tse1.mm.bing.net/th?id=OIP.awS8uuaYSGzpa2cJWZF5oQHaJL&pid=Api&P=0&h=180",
        "Rajasthan": "https://tse2.mm.bing.net/th?id=OIP.KKJ4ESgbiD0vts1IX-Kb1AHaGb&pid=Api&P=0&h=180",
        "Sikkim": "https://tse2.mm.bing.net/th?id=OIP.AC5gG0FqdzxqtoTRZSC2MAHaHl&pid=Api&P=0&h=180",
        "Tamil Nadu": "https://tse4.mm.bing.net/th?id=OIP.sQ9kGS-83t672f6flpVPwwHaIH&pid=Api&P=0&h=180",
        "Telangana": ts,
        "Tripura": tripura,
        "Uttar Pradesh": "https://tse2.mm.bing.net/th?id=OIP.oksTMvegiJvJsd1A6AKAQAHaF_&pid=Api&P=0&h=180",
        "Uttarakhand": "https://tse1.mm.bing.net/th?id=OIP.esWC98o6ZCeMgSbgu6VvwwHaG9&pid=Api&P=0&h=180",
        "West Bengal": "https://tse2.mm.bing.net/th?id=OIP.n2t7uyq2D9LPXZDXclsneAHaLI&pid=Api&P=0&h=180",
        "Andaman & Nicobar": ann,
        "Chandigarh": cdgr,
        "Dadra & Nagar Haveli": dnh,
        "Lakshadweep": "https://tse3.mm.bing.net/th?id=OIP.V2w4YLW1MpvO0U0PCHbj-QHaFW&pid=Api&P=0&h=180",
        "Delhi": delhi,
        "Puducherry": "https://tse4.mm.bing.net/th?id=OIP.6zd86HDl1pssA8NHngdVVgHaHD&pid=Api&P=0&h=180",
        "Uttaranchal":"https://tse2.mm.bing.net/th?id=OIP.8npFDBYGtuJS6OIXOiHUogHaGx&pid=Api&P=0&h=180",
        "Daman and Diu":dnd,
        "Jammu & Kashmir":"https://tse3.mm.bing.net/th?id=OIP.upzMwiEIvvoT9PcLUSLxvAHaGL&pid=Api&P=0&h=180"
    };

    const token = localStorage.getItem('token');
    const [open, setOpen] = useState(false);
    const handleLinkClick = () => {
        setOpen(false);
        showToast('error', ('Please Login to use additional features'));
    };

    const handleStateClick = (state) => {
        if(gid === "507"){
         navigate(`/cityPage/${state}`);
        }
        else{
         navigate(`/state/${state}/city/all`)
        } 
     };

    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Initialize states and filteredStates with data from statesData
        const stateNames = statesData.states.map((state) => state.name);
        setStates(stateNames);
        setFilteredStates(stateNames);
    }, []);

    const handleSearch = (value) => {
        setSearchTerm(value);
        if (value.trim() === '') {
            setFilteredStates(states);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filtered = states.filter((state) =>
                state.toLowerCase().includes(lowercasedValue)
            );
            setFilteredStates(filtered);
        }
    };

    const handleReset = () => {
        setSearchTerm('');
        setFilteredStates(states);
    };


    return (
        <div class="mainsec">
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span class="bc-link">{t('label_states_in_india')}</span>
                </div>
                
                <div></div>
            </div>
            <div className='f2 font-weight-bold mb-3 mt-3 container breadcrumb-link'>
                <Link style={{ color: 'black' }} to='/'>
                    <span class="bc-active-link">{t('Home')}</span>
                </Link> - <Link style={{ color: 'black' }} to='/templeCtgry'>
                    <span class="bc-active-link">{t("label_temples_list")}</span>
                </Link> - <span class="bc-link">{t('label_states_in_india')}</span>
            </div>
            <div class="mainsec-block">
                <div class="f2 font-weight-bold  container title-head-txt" style={{ overflowX: 'auto', paddingBottom: '10px', whiteSpace: "nowrap" }} >
                    {token ? (
                        <Link to='/addPage'><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("add_temple")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    )}
                    <Link to='/recentlyViewed'><button type="button" class="declineButton ">{t("lable_recent_view")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    <Link to='/nearby'> <button type="button" class="declineButton ">{t('label_temples_by_map')}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    {token ? (
                        <Link to='/searchFavourite'><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    ) : (
                        <Link className="my-link2" onClick={handleLinkClick}><button type="button" class="declineButton ">{t("page_title_fav")}<i class="fa-solid fa-circle-right animate_animated animate_heartBeat"></i></button></Link>
                    )}
                </div>


                {/* <div className='f2 font-weight-bold mb-2 mt-4 container title-head-txt'>
                
                Temple Categories</div> */}
                <div className="container">
                    <div class="container">
                        <div class="card-text-title d-flex justify-content-between mob-search-row">
                            <div class="d-flex align-items-center">
                                {t("label_states_in_india")}
                            </div>

                            <div class="sec-cat">
                                <Search
                                    placeholder="Search states"
                                    value={searchTerm}
                                    onSearch={handleSearch}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </div>
                            {/* <span>| {t("label_temple_divided_groups")}</span> */}
                        </div>
                    </div>

                    <div className="tiles-main">
                        {filteredStates.map((state, index) => (
                            // <Link to={/cityPage/${state}} key={index}>
                                <div className="tiles-design-temple-cat animate_animated animate_flipInX"  onClick={() => handleStateClick(state)}>
                                    <div className="title-img-bg">
                                        <img className="card-img-temple-cat" alt="temples" src={stateImages[state] || india} />
                                    </div>
                                    <div className="tile-text-temple-cat">
                                        <span>{state}</span> <span>{state}</span>
                                    </div>
                                </div>
                            // </Link>
                        ))}

                    </div>
                </div>

                <Footer />
            </div>
        </div>



    )

}

export default TempleInIndia;