import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Skeleton, message, Form, Input, Button, Space, DatePicker, Result } from 'antd';
import { getDailyHoroscopeDetailsList, getDailyHoroscopeDetailsLoading, getHoroDetailsByDtError, getHoroDetailsByDtList, getHoroDetailsByDtLoading, getWeeklyHoroscopeDetailsList, getWeeklyHoroscopeDetailsLoading, getWeeklyHoroscopeList, getWeeklyHoroscopeLoading } from '../../../../redux/selectors/selectors';
import { editHoroDetails, editWeeklyHoroDetails, fetchDailyHoroscopeDetails, fetchHoroDetailsByDate, fetchWeeklyHoroscope, fetchWeeklyHoroscopeDetails, updateHoroTitle, updateHoroTxt } from '../../../../redux/actions/acions';
import deftemples from '../../../../assets/images/templeicon.png';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import Footer from '../footer/footer';
import moment from 'moment';
import nodata from '../../../../assets/images/nodata5.jpg';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import ShowMoreText from '../../../common/showMoreText';

const DailyRaasiDetails = () => {
    const { t } = useTranslation();
    const storedLanguage = localStorage.getItem('lng') || 5;
    const { hid } = useParams();
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [title, setTitle] = useState(null);
    const [txt, setTxt] = useState(null);
    const [form] = Form.useForm();
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
    const [selectedWeek, setSelectedWeek] = useState(`${new Date().getFullYear()}-W${getCurrentWeek()}`);

    const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // For monthly
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const userid = localStorage.getItem('urole');
    const [isDateChanged, setIsDateChanged] = useState(false);

    function getCurrentWeek() {
        const today = new Date();
        const startOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDays = Math.floor((today - startOfYear) / (24 * 60 * 60 * 1000));
        return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7); // ISO week number
    }

    const { horoDet, horLoading, horoError } = useSelector((state) => ({
        horoDet: getWeeklyHoroscopeList(state),
        horLoading: getWeeklyHoroscopeLoading(state),
        horoError: getHoroDetailsByDtError(state),
    }));


    const fetchHoroscopeForDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('dhoro', storedLanguage, formattedDate, failure));
    };

    const fetchHoroscopeForWeeklyDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('whoro', storedLanguage, formattedDate, failure));
    };


    const fetchHoroscopeForMonthlyDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('mhoro', storedLanguage, formattedDate, failure));
    };

    const fetchHoroscopeForYearlyDate = (date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        dispatch(fetchWeeklyHoroscope('yhoro', storedLanguage, formattedDate, failure));
    };

    useEffect(() => {
        if (selectedItem === "weekly") {
            dispatch(fetchWeeklyHoroscope('whoro', storedLanguage, selectedWeek, failure));
        }
        else {
            fetchHoroscopeForDate(selectedDate);
        }
    }, [storedLanguage, dispatch, selectedDate]);

    // const onChange = (event) => {
    //     const date = event.target.value;
    //     setSelectedDate(date);
    //     setIsDateChanged(true);
    //     fetchHoroscopeForDate(date);
    // };

    function getStartDateOfISOWeek(week, year) {
        const firstDayOfYear = new Date(year, 0, 1);
        const daysOffset = (week - 1) * 7;
        const firstWeekStart = new Date(
            firstDayOfYear.setDate(firstDayOfYear.getDate() - firstDayOfYear.getDay() + 1)
        ); // Adjust to Monday
        const startDate = new Date(firstWeekStart.setDate(firstWeekStart.getDate() + daysOffset));
        return startDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD
    }



    const onChange = (event) => {
        const date = event.target.value;

        if (selectedItem === "weekly") {
            const weekValue = event.target.value; // e.g., "2024-W49"
            updateWeekAndFetch(weekValue);
            setSelectedDate(new Date().toISOString().split('T')[0])
        } else if (selectedItem === "monthly") {
            // Monthly input format is "YYYY-MM", use the first day of the month
            const [year, month] = date.split("-");
            const startDate = `${year}-${month}-01`;
            setSelectedMonth(startDate);
            fetchHoroscopeForMonthlyDate(startDate);
        } else if (selectedItem === "yearly") {
            // Yearly input is just the year, use it as is
            setSelectedYear(date); // Store only the year (e.g., 2024)
            const startDate = `${date}-01-01`; // Format as needed for API call
            fetchHoroscopeForYearlyDate(startDate);
        } else {
            // Daily input (default)
            setSelectedDate(date);
            fetchHoroscopeForDate(date);
        }

        setIsDateChanged(true);
    };



    const { hDetails, hLoading } = useSelector((state) => ({
        hDetails: getWeeklyHoroscopeDetailsList(state),
        hLoading: getWeeklyHoroscopeDetailsLoading(state),
    }));

    // useEffect(() => {
    //     dispatch(fetchWeeklyHoroscopeDetails(hid, storedLanguage));
    // }, [storedLanguage, dispatch]);

    useEffect(() => {
        if (hDetails && hDetails.publish_date) {
            setSelectedDate(hDetails.publish_date);
        }
    }, [hDetails]);

    const success = () => {
        showToast('success', t('label_horoedit_success'));
        form.resetFields();
    };

    const failure = (msg) => {
        showToast('fail', msg);
    };

    const [editable, setEditable] = useState(false);
    const [buttonText, setButtonText] = useState(t("edit"));
    const token = localStorage.getItem('token');

    const handleCancelClick = () => {
        setButtonText(t('edit'));
        setEditable(false);
    };

    const handleEditClick = () => {
        if (!editable) {
            setButtonText(t("label_cancel"));
            setTitle(hDetails && hDetails.title);
            setTxt(hDetails && hDetails.txt);
        } else {
            setButtonText(t('edit'));
        }
        setEditable(!editable);
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleSaveClick = () => {
        form
            .validateFields()
            .then(() => {
                const values = form.getFieldsValue();
                const dataToSend = {
                    nakshatra: values.nakshatra || hDetails.nakshatra,
                    tithi: values.tithi || hDetails.tithi,
                    yg_time: values.yg_time || hDetails.yg_time,
                    rh_time: values.rh_time || hDetails.rh_time,
                    "mesha": values.mesha || hDetails.mesha,
                    "vrushab": values.vrushab || hDetails.vrushab,
                    "midhuna": values.midhuna || hDetails.midhuna,
                    "karkataka": values.karkataka || hDetails.karkataka,
                    "simha": values.simha || hDetails.simha,
                    "kanya": values.kanya || hDetails.kanya,
                    "thula": values.thula || hDetails.thula,
                    "vruchika": values.vruchika || hDetails.vruchika,
                    "dhanassu": values.dhanassu || hDetails.dhanassu,
                    "makara": values.makara || hDetails.makara,
                    "kumbha": values.kumbha || hDetails.kumbha,
                    "meena": values.meena || hDetails.meena,
                    "title": values.title || hDetails.title,
                    "txt": values.txt || hDetails.txt,
                };
                dispatch(editWeeklyHoroDetails(hid, storedLanguage, dataToSend, success, failure));
            })
    };

    useEffect(() => {
        if (hid) {
            dispatch(fetchWeeklyHoroscopeDetails(hid, storedLanguage));
        }
    }, [storedLanguage, dispatch]);

    const updateTitle = (e) => {
        dispatch(updateHoroTitle(hid, storedLanguage, { title: `${e.target.value}` }, () => showToast('success', ' Updated Title Successfully', 'failure', 'Error occured')))
    };

    const updateTxt = (e) => {
        dispatch(updateHoroTxt(hid, storedLanguage, { txt: `${e.target.value}` }, () => showToast('success', ' Updated Text Successfully', 'failure', 'Error occured')))
    };

    const dataToDisplay = isDateChanged ? horoDet : hid ? hDetails : horoDet;

    const handleLinkClick = () => {
        // setOpen(false);
        message.info('User does not have permission !');
    };

    const handlePrevious = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() - 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    const handleNext = () => {
        const newDate = new Date(selectedDate);
        newDate.setDate(newDate.getDate() + 1);
        const formattedDate = newDate.toISOString().split('T')[0];
        setSelectedDate(formattedDate);
        fetchHoroscopeForDate(formattedDate);
        setIsDateChanged(true);
    };

    const getISOWeek = (date) => {
        const newDate = new Date(date.getTime());
        newDate.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year
        newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));
        // January 4 is always in week 1
        const week1 = new Date(newDate.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week 1
        return (
            1 +
            Math.round(
                ((newDate.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) /
                7
            )
        );
    };

    const updateWeekAndFetch = (weekValue) => {
        setSelectedWeek(weekValue); // Update the week input field

        // Extract year and week from the input value
        const [year, week] = weekValue.split('-W');

        // Use `moment` to calculate the start date of the week
        const startOfWeek = moment().year(year).isoWeek(week).startOf('isoWeek').format('YYYY-MM-DD');

        fetchHoroscopeForWeeklyDate(startOfWeek); // Fetch horoscope data for the selected week
        setIsDateChanged(true);
    };


    const handleWeeklyPrevious = () => {
        const [year, week] = selectedWeek.split('-W'); // Current year and week
        const previousWeek = moment().year(year).isoWeek(week).subtract(1, 'week'); // Go to previous week
        const previousWeekValue = `${previousWeek.year()}-W${previousWeek.isoWeek().toString().padStart(2, '0')}`; // Format as YYYY-WWW

        updateWeekAndFetch(previousWeekValue); // Update week and fetch data
    };

    const handleWeeklyNext = () => {
        const [year, week] = selectedWeek.split('-W'); // Current year and week
        const nextWeek = moment().year(year).isoWeek(week).add(1, 'week'); // Go to next week
        const nextWeekValue = `${nextWeek.year()}-W${nextWeek.isoWeek().toString().padStart(2, '0')}`; // Format as YYYY-WWW

        updateWeekAndFetch(nextWeekValue); // Update week and fetch data
    };





    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const meshaRef = useRef(null);
    const vrushabaRef = useRef(null);
    const midhunaRef = useRef(null);
    const karkatakaRef = useRef(null);
    const simhaRef = useRef(null);
    const kanyaRef = useRef(null);
    const tulaRef = useRef(null);
    const vruschikaRef = useRef(null);
    const dhanasuRef = useRef(null);
    const makaraRef = useRef(null);
    const kumbaRef = useRef(null);
    const meenaRef = useRef(null);
    const categoryButtons = ['weekly', 'monthly', 'yearly'];
    const [selectedItem, setSelectedItem] = useState(null);
    const handleButtonClick = (category) => {
        if (category === "Temples") {
            setSelectedItem(category);
            fetchHoroscopeForDate()
        }
        else if (category === "weekly") {
            setSelectedItem(category);
            fetchHoroscopeForWeeklyDate()
        }
        else if (category === "monthly") {
            setSelectedItem(category);
            fetchHoroscopeForMonthlyDate()
        }
        else if (category === "yearly") {
            setSelectedItem(category);
            fetchHoroscopeForYearlyDate()
        }
        else {
            setSelectedItem(category);
        }
    };

    useEffect(() => {
        if (selectedItem === null) {
            setSelectedItem("Temples"); // Set default to "Temples"
        }
    }, [selectedItem]);

    return (
        <div className='mainsec'>
            <div class=" mob-nav fluid-container page-title justify-content-between">
                <div class="d-flex align-items-center left-sec-mob-nav">
                    <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
                    <span className="bc-link">{hid ? t("label_weekly_horoscope_det") : t("label_horoscope_details")}</span>
                </div>
                <div></div>
            </div>
            {contextHolder}

            <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>

                <Link style={{ color: 'black' }} to='/'>
                    <span className="bc-active-link">{t('Home')}</span>
                </Link> - <span className="bc-active-link" onClick={handleGoBack}> {t('label_horo')}</span> -&nbsp;<span className="bc-link">{hid ? t("label_daily_horoscope") : t("label_daily_horoscope")}</span>
            </div>
            <div className="bg-details-highlight" style={{ overflowX: 'auto', whiteSpace: "nowrap" }}>
                <div className="details-headertxt-singleLine container">
                    <div className="header_txt">
                        {dataToDisplay && dataToDisplay.title}
                    </div>
                    <div class="d-flex justify-content-between details-headertxt-singleLine-btns" >

                        {/* Temples button */}
                        <button
                            type="button"
                            className={`declineButton ${selectedItem === "Temples" ? 'selected' : ''}`}
                            onClick={() => handleButtonClick("Temples")}
                        >
                            {t("label_daily")}
                        </button>


                        {categoryButtons.map((category, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`declineButton ${selectedItem === category ? 'selected' : ''}`}
                                onClick={() => handleButtonClick(category)}
                            >
                                {t('label_' + category)}

                            </button>
                        ))}
                    </div>


                    {/* {(userid === "AD" || userid === "AS") && (
                        <div className="container" style={{ display: 'flex', justifyContent: 'end' }}>
                             <Link to='/editDailyHoroscope/0' className="btn btn-warning shadow-0 yellowbtn">{t("label_create_weekly_horo")}</Link>
                        </div>
                    )} */}
                </div>
            </div>
            <div class="d-flex details-icons-main-horoscope-tile">
                <div className="details-icons-main-horoscope ">
                    <div class="zodaic-logo-main zs-bg1" onClick={() => scrollToSection(meshaRef)}>
                        <div class="zs-icon-top zs-aries-icon"></div>
                        <span className='names'>{t("label_mesha")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg2" onClick={() => scrollToSection(vrushabaRef)}>
                        <div class="zs-icon-top zs-tarus-icon"></div>
                        <span className='names'>{t('label_vrushab')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg3" onClick={() => scrollToSection(midhunaRef)}>
                        <div class="zs-icon-top zs-gemini-icon"></div>
                        <span className='names'>{t('label_midhuna')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg4" onClick={() => scrollToSection(karkatakaRef)}>
                        <div class="zs-icon-top zs-cancer-icon"></div>
                        <span className='names'>{t("label_karktaka")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg5" onClick={() => scrollToSection(simhaRef)}>
                        <div class="zs-icon-top zs-leo-icon"></div>
                        <span className='names'>{t('label_simha')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg6" onClick={() => scrollToSection(kanyaRef)}>
                        <div class="zs-icon-top zs-virgo-icon"></div>
                        <span className='names'>{t('label_kanya')}</span>
                    </div>
                </div>
                <div className="details-icons-main-horoscope ">
                    <div class="zodaic-logo-main zs-bg7" onClick={() => scrollToSection(tulaRef)}>
                        <div class="zs-icon-top zs-libra-icon"></div>
                        <span className='names'>{t("label_thula")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg8" onClick={() => scrollToSection(vruschikaRef)}>
                        <div class="zs-icon-top zs-scorpio-icon"></div>
                        <span className='names'>{t('label_vruchika')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg9" onClick={() => scrollToSection(dhanasuRef)}>
                        <div class="zs-icon-top zs-sagittarius-icon"></div>
                        <span className='names'>{t('label_dhanassu')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg10" onClick={() => scrollToSection(makaraRef)}>
                        <div class="zs-icon-top zs-aquarius-icon"></div>
                        <span className='names'>{t("label_makara")}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg11" onClick={() => scrollToSection(kumbaRef)}>
                        <div class="zs-icon-top zs-capricorn-icon"></div>
                        <span className='names'>{t('label_kumbha')}</span>
                    </div>
                    <div class="zodaic-logo-main zs-bg12" onClick={() => scrollToSection(meenaRef)}>
                        <div class="zs-icon-top zs-pisces-icon"></div>
                        <span className='names'>{t('label_meena')}</span>
                    </div>
                </div>
            </div>

            <div class="mainsec-block-horo-detailspage1">
                <div className='container'>
                    <div className="float-left w-100 "><h5 className="mt-4 mb-2">
                        <div className="button-row">

                            {selectedItem === 'weekly' ? (
                                <button  className="button_move" onClick={handleWeeklyPrevious}>{t('label_previous')}</button>
                            ) : (
                                <button  className="button_move" onClick={handlePrevious}>{t('label_previous')}</button>
                            )}
                            {selectedItem === "weekly" ? (
                                <input
                                    id="week-input"
                                    type="week"
                                    value={selectedWeek}
                                    onChange={onChange}
                                    className="date-input"
                                />
                            ) : selectedItem === "monthly" ? (
                                <input
                                    type="month"
                                    value={selectedMonth}
                                    onChange={onChange}
                                    className="date-input"
                                />
                            ) : selectedItem === "yearly" ? (
                                <select
                                    value={selectedYear}
                                    onChange={(e) => onChange(e)}
                                    className="date-input horoscope-year-input"
                                >
                                    {Array.from({ length: 201 }, (_, index) => 1900 + index).map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>

                            ) : (
                                <input
                                    type="date"
                                    value={selectedDate}
                                    onChange={onChange}
                                    className="date-input"
                                />
                            )}

                            {selectedItem === 'weekly' ? (
                                <button className="button_move" onClick={handleWeeklyNext}>{t('label_next')}</button>
                            ) : (
                                <button  className="button_move" onClick={handleNext}>{t('label_next')}</button>
                            )}
                            {(userid === "AD" || userid === "AS") && editable && (
                               <button className="button_move"
                                    onClick={handleSaveClick}
                                  
                                >
                                    {t("label_save")}
                                </button>
                            )}
                            {(userid === "AD" || userid === "AS") ? (
                                <button className="button_move"
                                    onClick={editable ? handleCancelClick : handleEditClick} > {buttonText} </button>
                            ) : ('')}
                            <a
                                className="button_move "
                                type="link"
                               
                                onClick={() => {
                                    if (navigator.share) {
                                        navigator
                                            .share({
                                                title: dataToDisplay && dataToDisplay.title,
                                                text: "Check out this horoscope!",
                                                url: `https://www.templeswiki.com/horoscopeDetails/${hid}`
                                            })
                                            .then(() => console.warn("Share successful."))
                                            .catch((error) => console.error("Error sharing:", error));
                                    } else {
                                        console.error("Web Share API not supported.");
                                    }
                                }}
                            >
                                <i title="Share" className="fa-solid fa-share-nodes"></i>
                            </a>
                        </div>
                    </h5></div>
                    
                    
                    { horLoading ? (
                        <p>Loading...</p>
                    ) : (
                    dataToDisplay ? (

                        <div className='horoscope-item'>


                            <Form form={form}>
                                <div className="active-details">
                                    <div className="tiles-designs">
                                        <Form.Item label={<Typography.Title level={4}>
                                            {t("label_title")}</Typography.Title>} name="title">
                                            {editable ? (
                                                <Input defaultValue={dataToDisplay.title} />
                                            ) : (
                                                <Typography.Text className='typ'>{dataToDisplay.title}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    {/* <div className="tiles-designs" ref={dhanasuRef}>
                                            <Form.Item label={<Typography.Title level={4}>{t("label_dhanassu")}</Typography.Title>} name="dhanassu">
                                                {editable ? (
                                                    <TextArea rows={4} defaultValue={dataToDisplay.dhanassu} style={{ marginTop: '6px' }} />
                                                ) : (
                                                    <Typography.Text className='typ'>{dataToDisplay.dhanassu}</Typography.Text>
                                                )}
                                            </Form.Item>
                                        </div> */}

                                    <div className="tiles-designs" ref={meshaRef}>

                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_mesha")}</span>
                                            <div class="zs-aries-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="mesha">
                                            {editable ? (

                                                <TextArea rows={4} defaultValue={dataToDisplay.mesha} style={{ marginTop: '6px' }} >

                                                </TextArea>
                                            ) : (
                                                <Typography.Text className='typ'>

                                                    {dataToDisplay.mesha}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={vrushabaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_vrushab")}</span>
                                            <div class="zs-tarus-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="vrushab">
                                            {editable ? (

                                                <TextArea rows={4} defaultValue={dataToDisplay.vrushab} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>


                                                    {dataToDisplay.vrushab}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={midhunaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_midhuna")}</span>
                                            <div class="zs-gemini-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="midhuna">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.midhuna} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>


                                                    {dataToDisplay.midhuna}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={karkatakaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_karktaka")}</span>
                                            <div class="zs-cancer-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="karkataka">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.karkataka} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>


                                                    {dataToDisplay.karkataka}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={simhaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_simha")}</span>
                                            <div class="zs-leo-icon-main zs-icon-main"></div></Typography.Title>} name="simha">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.simha} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>


                                                    {dataToDisplay.simha}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={kanyaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_kanya")}</span>
                                            <div class="zs-virgo-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="kanya">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.kanya} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>

                                                    {dataToDisplay.kanya}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>


                                    <div className="tiles-designs" ref={tulaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_thula")}</span>
                                            <div class="zs-libra-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="thula">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.thula} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>


                                                    {dataToDisplay.thula}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={vruschikaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_vruchika")}</span>
                                            <div class="zs-scorpio-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="vruchika">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.vruchika} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>

                                                    {dataToDisplay.vruchika}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={dhanasuRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_dhanassu")}</span>
                                            <div class="zs-sagittarius-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="dhanassu">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.dhanassu} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>


                                                    {dataToDisplay.dhanassu}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={makaraRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_makara")}</span>
                                            <div class="zs-capricorn-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="makara">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.makara} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>

                                                    {dataToDisplay.makara}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>


                                    <div className="tiles-designs" ref={kumbaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_kumbha")}</span>
                                            <div class="zs-aquarius-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="kumbha">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.kumbha} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>


                                                    {dataToDisplay.kumbha}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="tiles-designs" ref={meenaRef}>
                                        <Form.Item label={<Typography.Title level={4}>
                                            <span>{t("label_meena")}</span>
                                            <div class="zs-pisces-icon-main zs-icon-main"></div>
                                        </Typography.Title>} name="meena">
                                            {editable ? (
                                                <TextArea rows={4} defaultValue={dataToDisplay.meena} style={{ marginTop: '6px' }} />
                                            ) : (
                                                <Typography.Text className='typ'>

                                                    {dataToDisplay.meena}
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item
                                            label={<Typography.Title level={4}>{t("label_details")}</Typography.Title>}
                                            name="txt"
                                        >
                                            {editable ? (
                                                <TextArea
                                                    rows={4}
                                                    defaultValue={dataToDisplay.txt}
                                                    style={{ marginTop: '6px' }}
                                                />
                                            ) : (
                                                <Typography.Text className="typ">
                                                    {dataToDisplay.txt}
                                                    <ShowMoreText
                                                        charLimit={100}
                                                        text={dataToDisplay.txt}
                                                    />
                                                </Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                    <div className="tiles-designs">
                                        <Form.Item label={<Typography.Title level={4}>

                                            {t("label_author")}</Typography.Title>} name="title">
                                            {editable ? (
                                                <Input defaultValue={dataToDisplay.author} />
                                            ) : (
                                                <Typography.Text className='typ'>{dataToDisplay.author}</Typography.Text>
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    ) : (
                        <Result
                            status="404"
                            title={t("label_nodata_found")}
                            subTitle={t("label_no_horo_msg")}
                        />
                    ))
                    }
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default DailyRaasiDetails;
