import React, { useEffect, useState } from 'react';
import deftemples from '../../assets/images/templeicon.jpg'
import { Link } from 'react-router-dom';
import { getImageAPI } from '../../http-common';
import { message } from 'antd';
import spin from '../../assets/images/Rolling.gif'
import TempleMap from '../pages/Homepage/Temples/templeMap';
import { useDispatch } from 'react-redux';
import { deleteTempleImage, postUploadImageRequest } from '../../redux/actions/acions';
import temple1 from '../../assets/images/templeimg.png'
import temple2 from '../../assets/images/templeimg2.png'
import { useTranslation } from 'react-i18next';
import Hammer from 'react-hammerjs';
import article1 from "../../assets/images/altcles1.jpg";
import article2 from "../../assets/images/altcles-copy.jpg";
import article3 from "../../assets/images/altcles-3y.jpg";

const ResourceCard = ({ id, title, text, tList, temple, publish_date, author, favList, toggleFavorite, handleClick, link, target, city }) => {
    const [showImage, setShowImage] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 2000); // Hide the image after 2 seconds

        return () => {
            clearTimeout(timer);
        };
    }, []);



    const userRole = localStorage.getItem('urole')

    const articleImages = [
        { src: article1, alt: 'article1' },
        { src: article2, alt: 'article2' },
        { src: article3, alt: 'article3' },
    ];

    const getPriestImage = (resType, index = 0) => {

        switch (resType) {
            case 'mantras':
                return 'https://tse2.mm.bing.net/th?id=OIP.-rKodeHo2kIj6qwrnp2syAHaHD&pid=Api&P=0&h=180';
            case 'books':
                return 'https://scholarlykitchen.sspnet.org/wp-content/uploads/2018/07/iStock-506432952.jpg';
            case 'audios':
                return 'https://tse1.mm.bing.net/th?id=OIP.OLnrsvISPCD-Y65PfWSmtQHaE8&pid=Api&P=0&h=180';
            case 'livetv':
                return 'https://thesonofgodorg.files.wordpress.com/2018/01/01.png';
            case 'videos':
                return 'https://tse4.mm.bing.net/th?id=OIP.nHqa9osiCo-8DK0cbD4VPAHaEK&pid=Api&P=0&h=180';
            default:
                return deftemples; // Default image
        }
    };


    const isPriestPage = window.location.pathname.includes('/books') ||
        window.location.pathname.includes('/articles') ||
        window.location.pathname.includes('/mantras') ||
        window.location.pathname.includes('/audios') ||
        window.location.pathname.includes('/priestBySpclty') ||
        window.location.pathname.includes('/templeDetails');

    return (
        <>




            <div class={`col-lg-${text === 'audios' ? 4 : 3} col-md-6 col-sm-12 animate__animated animate__flipInX`}>
                <div class="weather-card one" >
                    <div class={`top ${(text === 'articles') ? 'article-image' : text === 'books' ? 'books-image' : text === 'mantras' ? 'mantras-image' : 'audio-image'}`}>
                        <div class="wrapper">
                            <h3 class="location" onClick={handleClick}>

                                <div>{title}</div></h3>
                            <div class="author-sec">
                                <h1 class="heading2" >
                                    <span class="face-icon"></span>
                                    <span>
                                        <span> {author}</span>
                                        <div class="pub-on">{publish_date} &nbsp;</div>
                                    </span>
                                </h1>
                                <span class="d-flex">
                                    {(title === 'articles' || title === 'mantras') &&
                                        < div class="art-sub" >Subscribe</div>
                                    }
                                    <div className="tile-quicklink1">
                                        {userRole === 'AS' || userRole === 'AD' && (
                                            <a href={`/editResource/${temple.rid}`} className="share-button1">
                                                <i className="fa-solid fa-edit"></i>
                                            </a>
                                        )}
                                        <a
                                            className="share-button1"
                                            onClick={() => {
                                                if (navigator.share) {
                                                    navigator.share({
                                                        title: "Know " + title,
                                                        text: "Know " + title,
                                                        url: `https://www.templeswiki.com/${text}/${temple.rid}`,
                                                    })
                                                        .then(() => console.warn("Share successful."))
                                                        .catch((error) => console.error("Error sharing:", error));
                                                } else {
                                                    // Fallback for browsers that do not support the Web Share API
                                                    console.error("Web Share API not supported.");
                                                }
                                            }}
                                        >
                                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                                        </a>
                                        {favList && (
                                            <a
                                                className="share-button1"
                                                onClick={() => toggleFavorite(temple, title)}
                                            >
                                                {favList.some(t => t === id) ? (
                                                    <i className="fa-solid fa-heart"></i>
                                                ) : (
                                                    <i className="fa-regular fa-heart"></i>
                                                )}
                                            </a>
                                        )}
                                    </div>
                                </span>
                            </div>


                        </div>
                    </div>
                    {(text === 'mantras' || text === 'articles') &&

                        <div class="bottom">
                            <div class="wrapper">
                                <ul class="forecast">

                                    <li class="active">
                                        <span class="date">{city}</span>
                                        <div class="showmore">
                                            <button class="button_move" onClick={(e) => {
                                                if (text === 'articles' && temple.src_url === 'www.templeswiki.com') {
                                                    e.preventDefault();
                                                    handleClick();
                                                }
                                                else if (text === 'mantras' && temple.src_url === 'www.templeswiki.com') {
                                                    e.preventDefault();
                                                    handleClick();
                                                }
                                            }}>ShowMore</button>
                                        </div>
                                    </li>
                                    <li>


                                    </li>
                                </ul>
                            </div>
                        </div>

                    }
                    {(text === 'audios') &&
                        <div class="bottom">
                            <div class="wrapper">
                                <ul class="forecast">

                                    <li class="active">
                                        {/* <span class="date">{city}</span> */}
                                        <div class="showmore">
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: '10%'

                                            }}>
                                                <audio id={'song00' + id} controls="controls" onLoadedMetadata={event => console.log("MP3=" + event.target.duration)}>
                                                    <source src={link} type="audio/mpeg" />
                                                </audio>
                                            </div>
                                        </div>
                                        <span class="date">{city}</span>
                                    </li>
                                    <li>


                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                </div >
            </div >




            {/* <div >
                    <a
                        href={link}
                        target={target}
                        onClick={(e) => {
                            if (text === 'articles' && temple.src_url === 'www.templeswiki.com') {
                                e.preventDefault(); // Prevent navigation for articles or templeswiki.com
                                handleClick(); // Call the handleClick to show the popup
                            }
                            else if (text === 'mantras' && temple.src_url === 'www.templeswiki.com') {
                                e.preventDefault(); // Prevent navigation for articles or templeswiki.com
                                handleClick(); // Call the handleClick to show the popup
                            }
                        }}
                    >
                        {(temple && temple.imgpath != null) ? (

                            <div className="card-img-container">

                            </div>
                        ) : isPriestPage && temple.res_type === 'articles' ? (
                            <div className="card-img-container">
                                <div className="card-img">
                                    <img
                                        className="card-img"
                                        alt={articleImages[id % articleImages.length].alt}
                                        src={articleImages[id % articleImages.length].src}
                                    />
                                </div>
                            </div>
                        ) : (
                            isPriestPage ? (
                                <img className="card-img" alt="temples" src={getPriestImage(temple.res_type)} />
                            ) : (
                                <img className="card-img" alt="temples" src={deftemples} />
                            )
                        )}

                        <div className="tile-text">
                            <div>

                                <h6 className="home-temple-text" title={title}>{title}</h6>
                                <p className="mb-1 f-grey tile-add">{city}</p>
                            </div>
                        </div>
                    </a>
                    <div className="tile-quicklink">

                        {userRole === 'AS' || userRole === 'AD' && (
                            <a href={`/editResource/${temple.rid}`} className="icon-button">
                                <i className="fa-solid fa-edit"></i>
                            </a>
                        )}
                        <a
                            className="share-button"
                            onClick={() => {
                                if (navigator.share) {
                                    navigator.share({
                                        title: "Know " + title,
                                        text: "Know " + title,
                                        url: `https://www.templeswiki.com/templeDetails/${id}?prevPage=${window.location.pathname}`
                                    })
                                        .then(() => console.warn("Share successful."))
                                        .catch((error) => console.error("Error sharing:", error));
                                } else {
                                    // Fallback for browsers that do not support the Web Share API
                                    console.error("Web Share API not supported.");
                                }
                            }}
                        >
                            <i title="Share" className="fa-solid fa-share-nodes"></i>
                        </a>
                        {favList && (
                            <a
                                className="share-button"
                                onClick={() => toggleFavorite(temple, title)}
                            >
                                {favList.some(t => t === id) ? (
                                    <i className="fa-solid fa-heart"></i>
                                ) : (
                                    <i className="fa-regular fa-heart"></i>
                                )}
                            </a>
                        )}
                    </div>
                </div> */}


        </>


    )
}

export default ResourceCard;