import HTTPClient from "../http-common";
import API_KEYS from "./apiKeys";

class ServicesDataService {

  getServicesByUser(tid, lr) {
    return HTTPClient.getHttp().get(`/service/temple/${tid}/lr/${lr}`,{
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.SERVICE_API_KEY
      }
    });
  }

  getServicesByAdmin(lr) {
    return HTTPClient.getAuthHttp().get(`/service/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getServicesDetails(id) {
    return HTTPClient.getHttp().get(`/service/${id}`,{
      headers: {
        'X-API-CID': API_KEYS.getClientToken(),
        'X-API-KEY': API_KEYS.SERVICE_API_KEY
      }
    });
  }

  createService(data) {
    return HTTPClient.getAuthHttp().post("/service", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }
  
  updateServiceStatus(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/active`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceBackBefore(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/bookbefore`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceAmount(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/amount`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceDate(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/date`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceCapacity(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/capacity`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceNotes(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/notes`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceTitle(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/title`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceType(servid, data) {
    return HTTPClient.getAuthHttp().put(`/service/${servid}/type`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }
  createServiceRegistration(data) {
    return HTTPClient.getAuthHttp().post("/servicereg", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  createServiceRegistrationPOS(data) {
    return HTTPClient.getAuthHttp().post("/servicereg/pos", data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getTempleServiceRegistration(lr) {
    return HTTPClient.getAuthHttp().get(`/servicereg/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getUserServiceRegistration(lr) {
    return HTTPClient.getAuthHttp().get(`/servicereg/user/lr/${lr}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  getServiceRegDetails(sregid) {
    return HTTPClient.getAuthHttp().get(`/servicereg/${sregid}`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  postServicesRegByAd() {
    return HTTPClient.getAuthHttp().post(`/servicereg/pos`, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationAmount(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/amount`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationQuantity(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/quantity`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationPhone(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/phone`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationAddr(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/addr`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationName(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/devotee`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationBill(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/bill`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationNotes(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/notes`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationAdminNotes(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/adminnotes`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  updateServiceRegistrationAdminStatus(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/status`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  changeComplaintStatus(reqid, data) {
    return HTTPClient.getAuthHttp().put(`temple/complaints/${reqid}/status`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

  cancelService(servid, data) {
    return HTTPClient.getAuthHttp().put(`/servicereg/${servid}/cancel`, data, {
      headers: {
        'X-API-CID': API_KEYS.getClientToken()
      }
    });
  }

}

export default new ServicesDataService();