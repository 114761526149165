import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Typography from 'antd/es/typography/Typography';
import { getBooksByTagError, getBooksByTagList, getBooksByTagLoading, getBooksError, getBooksFavouritesError, getBooksFavouritesList, getBooksFavouritesLoading, getBooksList, getBooksLoading, getFavouritesError, getFavouritesList, getFavouritesLoading, getGodNames, getGodNamesError, getGodNamesLoading, getResourceByTxtError, getResourceByTxtList, getResourceByTxtLoading, getResourceDetailsList } from '../../../../redux/selectors/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks, fetchBooksByTag, fetchBooksFavourites, fetchFavourites, fetchGodNames, fetchResArticles, fetchResourceByTxt, fetchResourceDetails, postBooksFavourites, postFavourites, removeBooksFavourites, removeFavourites, retrieveGodNames } from '../../../../redux/actions/acions';
import { Button, Card, Col, FloatButton, Image, Input, Modal, Result, Row, Skeleton, Space, Spin, message } from 'antd';
import deftemples from '../../../../assets/images/templeicon.jpg';
import { CloseCircleOutlined, EditOutlined, RocketTwoTone, SearchOutlined } from '@ant-design/icons';
import temple from '../../../../assets/images/img.png'
import { getImageAPI, getAudioAPI } from "../../../../http-common";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Error from '../error';
import TempleCard from '../../../common/card';
import Footer from '../footer/footer';
import showToast from '../../../../utils/showToast';
import CustomToastContainer from '../../../common/CustomToastContainer';
import aum from '../../../../assets/icons/om.jpg'
import deityImages from '../../../common/godImages';
import audioFile from '../../../../assets/audios/namo.mp3';
import Hammer from 'react-hammerjs';
import ResourceCard from '../../../common/resourceCard';

let last_rec = 0;
let selected_last_rec = 0;
const CommonResource = ({ title, description, currentPage, bDetails, fetchResource, mainSecBlockRef, text, src, bLoading, bError, tDetails, tLoading, tError, sList, sLoading, sError, nextTemplesList, prevTemplesList }) => {
  const { t } = useTranslation();
  const records_per_page = 15;
  const [isLoading, setIsLoading] = useState(false);
  const storedLanguage = localStorage.getItem('lng') || 5;
  const [did, setDid] = useState(0);
  const [tag, setTag] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const userid = localStorage.getItem('urole')
  const localEnv = false;
  const [filterId, setFilterId] = useState(0);
  const dispatch = useDispatch()
  const [messageApi, contextHolder] = message.useMessage();
  const [lastRec, setLastRec] = useState(0);
  const selected_per_page = 15;

  // const failure = (msg) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: msg,
  //   });
  // };
  const { uid } = useParams()



  function getStyle(godCategoryId) {
    if (godCategoryId === filterId) {
      return {

        boxShadow: '#d3d3d3 3px -2px 4px',
        borderRadius: '8px',
        filter: 'saturate(1)',
        border: '3px solid var(--colororange2)',
      };
    } else {
      return {
        border: '1px solid #ddd',
      };
    }
  }

  const { godCtgryList, godCtgryListError, godCtgryListLoading } = useSelector(state => ({

    godCtgryList: getGodNames(state) || [],
    godCtgryListLoading: getGodNamesLoading(state),
    godCtgryListError: getGodNamesError(state),
  }))


  const god = {
    1: 'Ganesha',
    2: 'Vishnu',
    3: 'Lakshmi',
    4: 'Durga',
    5: 'Sri Rama',
    6: 'Shiva',
    7: 'Krishna',
    8: 'Kartikeya',
    9: 'Hanuma',
    10: 'Brahma',
    11: 'Saraswati',
    12: 'Ayyappa',
    13: 'Saibaba',
    14: 'Narasimha',
    15: 'Surya',
    16: 'Budha',
  };

  const [refresh, setRefresh] = useState(false);

  const success = (tname) => {
    showToast('success', `${t('label_added')} ${t(tname)} ${t('label_to_favourites')}`);
  };

  const warning = (tname) => {
    showToast('info', `${t('label_removed')} ${t(tname)} ${t('label_from_favourites')}`);
  };

  const failure = (msg) => {
    showToast('error', t('label_loginerror'))
    // messageApi.open({
    //   type: 'error',
    //   content: 'Please Login to see additional features',
    // });
  };

  const [getFavouriteTemples, setGetFavoriteTemples] = useState(false);
  const [filledTemples, setFilledTemples] = useState({});

  const { fList, fLoading, fError } = useSelector(state => ({
    fList: getBooksFavouritesList(state),
    fLoading: getBooksFavouritesLoading(state),
    fError: getBooksFavouritesError(state),
  }));

  // useEffect(() => {
  //   {
  //     getToken && (
  //       dispatch(fetchBooksFavourites(text))
  //     )
  //   }
  // }, [getFavouriteTemples, refresh]);

  const cachedFavourites = JSON.parse(localStorage.getItem('articles')) || [];

  useEffect(() => {
    setIsFavourite(favList.includes(selectedArticle));
  }, [selectedArticle, favList]);


  useEffect(() => {
    if (getToken) {
      const cachedFavourites = JSON.parse(localStorage.getItem('articles')) || [];
      setFavList(cachedFavourites);
    }
  }, [getToken]);

  useEffect(() => {
    if (fList && fList.fav_list) {
      setFavList(fList.fav_list);
      localStorage.setItem('articles', JSON.stringify(fList.fav_list));
    }
  }, [fList]);

  const [favList, setFavList] = useState([]);

  // useEffect(() => {
  //   if (fList && fList.fav_list) {
  //     setFavList(fList.fav_list);
  //   }
  // }, [fList]);



  function addFavourites(rid) {
    dispatch(postBooksFavourites(text, rid, failure));
    setFavList(prevFavList => {
      const updatedFavList = [...prevFavList, rid];
      localStorage.setItem('articles', JSON.stringify(updatedFavList));
      return updatedFavList;
    });
  }

  function deleteFavourites(deleteId) {
    dispatch(removeBooksFavourites(text, deleteId, failure));
    setFavList(prevFavList => {
      const updatedFavList = prevFavList.filter(favId => favId !== deleteId);
      localStorage.setItem('articles', JSON.stringify(updatedFavList));
      return updatedFavList;
    });
  }

  // let favList = (fLoading === true || !Array.isArray(fList)) ? [] : fList.map(id => id.rid);

  const getToken = Boolean(localStorage.getItem('token'));

  function toggleFavorite(temple) {
    if (getToken) {
      const templeId = temple.rid;

      if (favList.includes(templeId)) {
        // Temple is in favList, remove it
        deleteFavourites(templeId, temple.title);
        warning(temple.title);
        // Update favList by removing the temple ID
        setFavList(prevFavList => {
          const updatedFavList = prevFavList.filter(id => id !== templeId);
          localStorage.setItem('articles', JSON.stringify(updatedFavList));
          return updatedFavList;
        });
      } else {
        // Temple is not in favList, add it
        addFavourites(templeId, temple.title);
        success(temple.title);
        // Update favList by adding the temple ID
        setFavList(prevFavList => {
          const updatedFavList = [...prevFavList, templeId];
          localStorage.setItem('articles', JSON.stringify(updatedFavList));
          return updatedFavList;
        });
      }
    } else {
      // Handle the case when there's no token
      failure("Please log in to favorite temples");
    }
  }


  const [filled, setFilled] = useState({});

  function toggleFilled(id) {
    setFilledTemples((prevFilled) => ({
      ...prevFilled,
      [id]: !prevFilled[id],
    }));
  }



  const handleButtonClick = (itemName) => {
    if (itemName === "All") {
      setSelectedItem(null); // Reset selectedItem to null to enable all buttons     
      dispatch(fetchResource(text, storedLanguage, did, 0));
    } else {
      setSelectedItem(itemName);
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, itemName, 0)); // Pass itemName as the tag
    }
  };

  useEffect(() => {
    if (selectedItem === null) {
      dispatch(fetchResource(text, storedLanguage, did, 0))
    }
    else if (selectedItem != null) {
      dispatch(fetchBooksByTag(text, storedLanguage, filterId, selectedItem, 0));
    }
  }, [storedLanguage, did])

  const categoryButtons = (text === 'audios')
    ? ['yoga', 'chanting', 'songs', 'bhajans', 'keertans']
    : ['kids', 'temple', 'puran', 'dharma', 'god', 'puja', 'festival'];

  function getCategoryImage(category) {
    switch (category) {
      case 'kids':
        return deftemples;
      case 'temple':
        return deftemples;
      case 'puran':
        return deftemples;
      // Add more cases for other categories as needed
      default:
        return deftemples;
    }
  }

  //   const [selectedArticle, setSelectedArticle] = useState(null);

  // useEffect(() => {
  //   if (selectedArticleId) {
  //     const article = bDetails.find(article => article.rid === selectedArticleId);
  //     setSelectedArticle(article);
  //   }
  // }, [selectedArticleId, bDetails]);



  const [isArticleModalVisible, setIsArticleModalVisible] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState(null);

  const closeModal = () => {
    setIsArticleModalVisible(false);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (isArticleModalVisible) {
        closeModal();
        event.preventDefault();
      }
    };
    const handleKeyDown = (event) => {
      if (isArticleModalVisible && event.key === 'Escape') {
        closeModal();
        event.preventDefault();
      }
    };
    if (isArticleModalVisible) {
      // Add history entry and listeners
      window.history.pushState({ popupOpen: true }, '');
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // Cleanup listeners when popup closes or component unmounts
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isArticleModalVisible, closeModal]);


  const handleArticleClick = (temple) => {
    // Merge all available `rid` lists
    const allDetails = [...(bDetails || []), ...(sList || []), ...(tDetails || [])];

    if (allDetails.length > 0) {
      const ridList = allDetails.map((article) => article.rid); // Extract all `rid`s
      localStorage.setItem('ridList', JSON.stringify(ridList));
      localStorage.setItem('currentRidIndex', ridList.indexOf(temple.rid)); // Store current index
    }

    // Check if the src_url is "www.templeswiki.com"
    if (temple.src_url === 'www.templeswiki.com') {
      localStorage.setItem('src_url', temple.src_url); // Store src_url in localStorage
      setSelectedArticleId(temple.rid); // Set selected article
      setIsArticleModalVisible(true); // Show popup
    } else {
      // Make sure the URL starts with http:// or https://
      let templeUrl = temple.src_url.trim();

      // If no protocol is present, prepend http://
      if (!templeUrl.startsWith('http://') && !templeUrl.startsWith('https://')) {
        templeUrl = `http://${templeUrl}`;
      }

      // Detect if the device is mobile
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // For mobile devices, navigate to the absolute URL
        window.location.href = templeUrl;
      } else {
        // For web, try opening the URL in a new tab
        const newWindow = window.open(templeUrl, '_blank', 'noopener,noreferrer');

        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          // Fallback to opening in the same tab if popups are blocked
          window.location.href = templeUrl;
        }
      }
    }
  };






  // Function to handle closing the article popup
  const handleArticleCancel = () => {
    setIsArticleModalVisible(false);
  };



  const handleNextArticle = () => {
    const ridList = JSON.parse(localStorage.getItem('ridList')) || [];
    const currentIndex = parseInt(localStorage.getItem('currentRidIndex'), 10);

    if (currentIndex >= 0 && currentIndex < ridList.length - 1) {
      const nextIndex = currentIndex + 1;
      setSelectedArticleId(ridList[nextIndex]);
      localStorage.setItem('currentRidIndex', nextIndex);

    }

  };

  const handlePrevArticle = () => {
    const ridList = JSON.parse(localStorage.getItem('ridList')) || [];
    const currentIndex = parseInt(localStorage.getItem('currentRidIndex'), 10);

    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      setSelectedArticleId(ridList[prevIndex]);
      localStorage.setItem('currentRidIndex', prevIndex);
    }
  };


  const [isFavourite, setIsFavourite] = useState(false);
  useEffect(() => {
    const cachedFavourites = JSON.parse(localStorage.getItem('articles')) || [];
    if (cachedFavourites) {
      setIsFavourite(cachedFavourites.includes(selectedArticle));
    }
    else {
      dispatch(fetchResArticles(storedLanguage, last_rec));
    }
  }, [selectedArticle]);




  // useEffect(() => {
  //   dispatch(fetchResourceByTxt(text, storedLanguage, searchText, 0));
  // }, [searchText]);

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (uid) {
      setSearchText(uid);
      if (uid.length >= 5) {
        dispatch(fetchResourceByTxt(text, storedLanguage, uid, 0));
      }
    }
  }, [uid, dispatch]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchText(newValue);

    // Call the API only if the length of the input value is 5 or more
    if (newValue.length >= 5) {
      dispatch(fetchResourceByTxt(text, storedLanguage, newValue, 0));
    }
  };

  const [isGodModalVisible, setIsGodModalVisible] = useState(false);
  const [selectedFilterGod, setSelectedFilterGod] = useState(null);

  const handleFilterGodClick = () => {
    setIsGodModalVisible(true);
  };

  const handleFilterGodCancel = () => {
    setIsGodModalVisible(false);
  };

  function filterTemplesList(gDid) {
    if (gDid === filterId) {
      setDid(0);
      setFilterId(0);
      dispatch(fetchResource(text, storedLanguage, 0, 0));
    } else {
      setDid(gDid);
      setFilterId(gDid);
      dispatch(fetchResource(text, storedLanguage, gDid, 0));
    }
  }

  // const nextTemplesList = async () => {
  //   setIsLoading(true);
  //   if (filterId !== null && selectedItem === null) {
  //     last_rec = last_rec + records_per_page;
  //     dispatch(fetchBooks(text, storedLanguage, did, last_rec));
  //   } else if (selectedItem !== null) {
  //     selected_last_rec = selected_last_rec + selected_per_page;
  //     dispatch(fetchBooksByTag(text, storedLanguage, filterId, selectedItem, selected_last_rec));
  //   }
  //   setIsLoading(false); // Ensure loading is set to false after the fetch
  // };

  // function prevTemplesList() {
  //   if (filterId !== null && selectedItem === null) {
  //     setLastRec(prevLastRec => Math.max(prevLastRec - records_per_page, 0));
  //     dispatch(fetchBooks(text, storedLanguage, did, last_rec));
  //   } else if (selectedItem !== null) {
  //     selected_last_rec = selected_last_rec - selected_per_page;
  //     dispatch(fetchBooksByTag(text, storedLanguage, filterId, selectedItem, selected_last_rec));
  //   }
  // }





  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (bDetails || sList || tDetails) {
      setLoading(false);
    }
  }, [bDetails, sList, tDetails]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Return an empty string if string is null or undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderContent = () => {
    const CardComponent = ResourceCard;

    if (loading) {
      return (
        <div className="loading">
          <Error />
        </div>
      );
    }

    if (!searchText && !selectedItem) {
      if (bDetails && bDetails.length > 0) {
        return bDetails.map((temple) => {
          const templeUrl = temple.src_url.startsWith("http") ? temple.src_url : `http://${temple.src_url}`;
          const target = (text === 'mantras' ? "_self" : "_blank");

          return (
            <CardComponent
              key={temple.rid}
              id={temple.rid}
              imgpath={temple.imgpath}
              title={temple.title}
              city={temple.info ? temple.info.split("<BR>").join("\n") : ''}
              temple={temple}
              favList={favList}
              toggleFavorite={toggleFavorite}
              toggleFilled={toggleFilled}
              link={(text === 'audios' ? getAudioAPI() + temple.src_url : templeUrl)}
              target={target}
              handleClick={() => handleArticleClick(temple)}
              text={text}
              publish_date={temple.publish_date}
              author={temple.author}
            />
          );
        });
      }

      return (
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_resources_msg")}
        />
      );
    }

    if (uid >= 5 || (searchText && searchText.length >= 5)) {
      if (sList && sList.length > 0) {
        return sList.map((temple) => (
          <CardComponent
            key={temple.rid}
            id={temple.rid}
            imgpath={temple.imgpath}
            title={temple.title}
            city={temple.info ? temple.info.split("<BR>").join("\n") : ''}
            temple={temple}
            favList={favList}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            link={(text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
            target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
            handleClick={() => handleArticleClick(temple)}
            text={text}
            publish_date={temple.publish_date}
            author={temple.author}
          />
        ));
      }
      return (
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_resources_msg")}
        />
      );
    }

    if (selectedItem != null) {
      if (tDetails && tDetails.length > 0) {
        return tDetails.map((temple) => (
          <CardComponent
            key={temple.rid}
            id={temple.rid}
            imgpath={temple.imgpath}
            title={temple.title}
            city={temple.info ? temple.info.split("<BR>").join("\n") : ''}
            temple={temple}
            favList={favList}
            toggleFavorite={toggleFavorite}
            toggleFilled={toggleFilled}
            link={(text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
            target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
            handleClick={() => handleArticleClick(temple)}
            text={text}
            publish_date={temple.publish_date}
            author={temple.author}
          />
        ));
      }
      return (
        <Result
          status="404"
          title={t("label_nodata_found")}
          subTitle={t("label_no_resources_msg")}
        />
      );
    }

    if (bDetails && bDetails.length > 0) {
      return bDetails.map((temple) => (
        <CardComponent
          key={temple.rid}
          id={temple.rid}
          imgpath={temple.imgpath}
          title={temple.title}
          city={temple.info ? temple.info.split("<BR>").join("\n") : ''}
          temple={temple}
          favList={favList}
          toggleFavorite={toggleFavorite}
          toggleFilled={toggleFilled}
          link={(text === 'mantras' ? `/editResource/${temple.rid}` : temple.src_url)}
          target={(text === 'mantras' || text === 'articles' ? "_self" : "_blank")}
          handleClick={() => handleArticleClick(temple)}
          text={text}
          publish_date={temple.publish_date}
          author={temple.author}
        />
      ));
    }

    return (
      <Result
        status="404"
        title={t("label_nodata_found")}
        subTitle={t("label_no_resources_msg")}
      />
    );
  };


  const { resDetails } = useSelector(state => ({
    resDetails: getResourceDetailsList(state) || [], // Default to empty array if null/undefined
  }));

  useEffect(() => {
    if (selectedArticleId) {
      const ridList = JSON.parse(localStorage.getItem('ridList')) || [];

      if (ridList.length > 0) {
        console.log("Fetching details for rid:", selectedArticleId);
        dispatch(fetchResourceDetails(storedLanguage, selectedArticleId, failure));
      } else {
        console.error("ridList is empty. Cannot navigate to next/prev article.");
      }
    }
  }, [selectedArticleId, storedLanguage, dispatch]);


  const selectedArticle = resDetails && resDetails.rid === selectedArticleId ? resDetails : null;

  return (
    <>
      {contextHolder}

      <div class="mainsec">

        <div class=" mob-nav fluid-container page-title justify-content-between">
          <div class="d-flex align-items-center left-sec-mob-nav">
            <Link onClick={handleGoBack} ><i class="fa-solid fa-angle-left"></i></Link>
            <span className="bc-link">{title}</span>
          </div>

          <div class="right-sec-mob-nav d-flex align-items-center">
            <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
              <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>

              {did !== 0 && (
                <>
                  <div className="detail-container">
                    <span className="god-button">{god[filterId]}</span>
                    <CloseCircleOutlined
                      className="close-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        setFilterId(0);
                        setDid(0);
                        dispatch(fetchResource(storedLanguage, 0, 0));
                      }}
                    />
                  </div>
                </>
              )}
              {did === 0 && (
                <div className="detail-container d-flex align-items-center">
                  <span className="detail-but-god ml-2">{t("label_all")}</span>
                </div>
              )}

            </button>
          </div>
        </div>


        <div className='f2 font-weight-bold mb-3 mt-3 container title-head-txt breadcrumb-link'>
          <div class="d-flex">
            {/* <Link to='/library' style={{ color: 'black' }} className='search-back'><i class="fa-solid fa-angle-left"></i></Link> */}

            <Link style={{ color: 'black' }} to='/'><span className="bc-active-link">
              {t('Home')}</span>
            </Link>&nbsp;-&nbsp;{' '}
            <Link to='/library'>
              <span className="bc-active-link" > {t('label_library')}</span></Link>&nbsp;- <span className="bc-link ml-2 f2"> {title}</span>{' '}&nbsp;&nbsp;
            <button className="declineButton god-filter-btn" onClick={handleFilterGodClick}>{t("label_sel_god")}
              <i class="fa-solid fa-om animate__animated animate__heartBeat" style={{ display: 'block' }}></i>
              {did !== 0 && (
                <>
                  <div className="detail-container">
                    <span className="god-button">{god[filterId]}</span>
                    <CloseCircleOutlined
                      className="close-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        setFilterId(0);
                        setDid(0);
                        dispatch(fetchResource(storedLanguage, 0, 0));
                      }}
                    />
                  </div>
                </>
              )}
              {did === 0 && (
                <div className="detail-container d-flex align-items-center">
                  <span className="detail-but-god ml-2">{t("label_all")}</span>
                </div>
              )}

            </button>
          </div>
        </div>

        <div class="popup-bg" style={{ display: isGodModalVisible ? 'flex' : 'none' }}>
          <CustomToastContainer />
          <div class="popup-main-filter animate__animated animate__fadeInUp ">
            <div class="popup-main-header-filter">
              <span class="popup-main-header-text">{t("label_god_categ")}</span>
              <div class="popup-close" onClick={handleFilterGodCancel}><i class="fa-solid fa-xmark"></i></div>
              <div class="popup-back" onClick={handleFilterGodCancel}><i class="fa-solid fa-chevron-left"></i></div>
            </div>
            <div class="popup-main-container-filter">
              <span className="f13-fw-600 d-block float-left">{t('label_god_categ')}</span>
              <div class="d-block float-left content-filter">
                <div className=" god-category-list1">


                  <div class="all-gods-sec">
                    {t('label_all')}
                  </div>
                  <div class="god_select">
                    <div class="god_pic">
                      <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                        onClick={() => {
                          setFilterId(0);
                          setDid(0);
                          dispatch(fetchResource(storedLanguage, 0, 0));
                          setIsGodModalVisible(false);
                        }}
                      />

                      {/* {categoryButtons.map((category, index) => (

                        <div class="god_pic" key={index}>
                          <button style={getStyle(0)} class="declineButton" alt={category}
                            onClick={() => {
                              handleButtonClick(category);
                              setIsGodModalVisible(false);
                            }}
                          >
                            {t('label_' + category)}
                          </button>
                          
                        </div>
                      ))} */}
                    </div>
                  </div>


                  <div class="all-gods-sec">
                    {t('label_filter_by_god')}
                  </div>
                  {/* Display other gods in rows */}
                  <Row gutter={[16, 16]}>
                    {godCtgryList &&
                      godCtgryList.map((godCtgry, index) => (
                        <Col key={godCtgry.avatar}>
                          <div class="god_select">
                            <div class="god_pic">
                              <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                                onClick={() => {
                                  filterTemplesList(godCtgry.avatar);
                                  setIsGodModalVisible(false);
                                }}
                              />
                              <p>{godCtgry.deity}</p>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </div>
            <div class="popup-main-footer">
              <a className="button_move" onClick={handleFilterGodCancel}>{t('label_cancel')}</a>
            </div>
          </div>
        </div>
        {/* <Modal visible={isGodModalVisible} footer={null}>
          <div className="diag-header">
            {t("label_god_categ")}
            <span class="diag-close">
              <i class="fa-solid fa-xmark" onClick={handleFilterGodCancel}></i>
            </span>
          </div>
          <div className="dialog-content god-category-list1">


            <div class="all-gods-sec">
              All
            </div>
            <div class="god_select">
              <div class="god_pic">
                <img style={getStyle(0)} className='dietyImg' src={aum} alt="All"
                  onClick={() => {
                    setFilterId(0);
                    setDid(0);
                    dispatch(fetchResource(storedLanguage, 0, 0));
                    setIsGodModalVisible(false);
                  }}
                />
               
                {categoryButtons.map((category, index) => (

                  <div class="god_pic" key={index}>
                    <button style={getStyle(0)} class="declineButton" alt={category}
                      onClick={() => {
                        handleButtonClick(category);
                        setIsGodModalVisible(false);
                      }}
                    >
                      {t('label_'+category)}
                    </button>
                    
                  </div>
                ))}
              </div>
            </div>


            <div class="all-gods-sec">
              Filter By God
            </div>
            
            <Row gutter={[16, 16]}>
              {godCtgryList &&
                godCtgryList.map((godCtgry, index) => (
                  <Col key={godCtgry.avatar}>
                    <div class="god_select">
                      <div class="god_pic">
                        <img style={getStyle(godCtgry.avatar)} src={deityImages[godCtgry.avatar]}
                          onClick={() => {
                            filterTemplesList(godCtgry.avatar);
                            setIsGodModalVisible(false);
                          }}
                        />
                        <p>{godCtgry.deity}</p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>

          <div className="dialog-buttons">
            <Button className="login-form-button" onClick={handleFilterGodCancel}>{t('label_cancel')}</Button>
          </div>
        </Modal> */}
        <div class=" mainsec-block" ref={mainSecBlockRef}>
          <div class="details-icons-main"><div class="container details-icons">
            <button
              className={`details-icons-list ${selectedItem === null ? 'selected' : ''}`}
              onClick={() => handleButtonClick("All")}>{t('label_all')}</button>
            {categoryButtons.map((category, index) => (
              <button
                key={index}
                type="button"
                className={`details-icons-list ${selectedItem === category ? 'selected' : ''}`}
                onClick={() => handleButtonClick(category)}
              >
                {t('label_' + category)}

              </button>
            ))}
          </div>
          </div>
          {/* <div class="container cat-txt ">
            <div class="cat-detail">
              <span class="f3 d-flex justify-content-between">
                <div><b>{title}</b> &nbsp; &nbsp;<i class="fa-solid fa-volume-high"></i></div>
              </span>
              <p class="f1">{description}
              </p>
            </div></div> */}
          {/* <div className='container '>

            <Input
              className='resource-search mb-3 mt-3'
              name="query"
              placeholder="Enter search keyword"
              value={searchText}
              onChange={handleInputChange}
              allowClear
              prefix={<SearchOutlined />}

            />

          </div> */}


          <div className="container pt-3">
            <Row>
              {renderContent()}
            </Row>
            {(text === 'articles' || text === 'mantras') && (
              <div class="popup-bg" style={{ display: isArticleModalVisible ? 'flex' : 'none' }}>
                <CustomToastContainer />
                <div class="popup-main-artical animate__animated animate__fadeInUp "

                >

                  <div class="popup-main-header-artical">
                    <div class="flex-grow-1 overflow-hidden">
                      <span class="popup-main-header-text-artical">{capitalizeFirstLetter(resDetails && resDetails.title) || 'N/A'}</span>
                      <div class="popup-close" onClick={handleArticleCancel}><i class="fa-solid fa-xmark"></i></div>
                      <div class="popup-back" onClick={handleArticleCancel}><i class="fa-solid fa-chevron-left"></i></div>
                      <p><span class="article-titletxt">{t('label_author')} :</span> {capitalizeFirstLetter(resDetails && resDetails.author) || 'N/A'}</p>
                      <p><span class="article-titletxt">{t('label_pub_date')} :</span> {capitalizeFirstLetter(resDetails && resDetails.publish_date) || 'N/A'}</p>
                    </div>
                    <div class="article-image"></div>
                    <div>
                    </div>

                  </div>
                  <Hammer
                    onSwipe={(event) => {
                      if (event.direction === 2) { // Left swipe for next
                        handleNextArticle();
                      } else if (event.direction === 4) { // Right swipe for previous
                        handlePrevArticle();
                      }
                    }}
                  >
                    <div class="popup-main-container-artical">
                      <div class="htinner-popup-main-container-artical ">
                        {/* <Link className='ignore-styles' to={`/editResource/${selectedArticle && selectedArticle.rid}?prevPage=${window.location.pathname}`}>
                      <img
                        className="grp-descr-img"
                        alt={selectedArticle ? selectedArticle.tname : 'Default Temple'}
                        src={selectedArticle && selectedArticle.imgpath ? getImageAPI() + selectedArticle.imgpath : deftemples}
                      />
                    </Link> */}


                        <div className='dialog-content-text-artical ' style={{ whiteSpace: "pre-wrap" }}>
                          <Hammer
                            onSwipe={(event) => {
                              if (event.direction === 2) { // Left swipe for next
                                handleNextArticle();
                              } else if (event.direction === 4) { // Right swipe for previous
                                handlePrevArticle();
                              }
                            }}
                          >
                            <div  class={`dialog-content-text-articaltxt `}
                            >

                              {/* <p class="detailstxt-artical-title"> {capitalizeFirstLetter(selectedArticle && selectedArticle.title) || 'N/A'}</p> */}
                              <Hammer
                                onSwipe={(event) => {
                                  if (event.direction === 2) { // Left swipe for next
                                    handleNextArticle();
                                  } else if (event.direction === 4) { // Right swipe for previous
                                    handlePrevArticle();
                                  }
                                }}
                              >

                                <p class={`dialog-content-text-articaltxt-articalmain-data ${text === 'mantras' ? 'scrollable' : ''}`}>
                                  {resDetails && resDetails.txt
                                    ? capitalizeFirstLetter(
                                      resDetails.txt
                                        .split("<BR>")
                                        .map(line => {
                                          if (/<B>.*<\/B>/i.test(line)) {
                                            return `**${line.replace(/<B>|<\/B>/gi, "")}**`;
                                          }
                                          return line;
                                        })
                                        .join("\n")
                                    )
                                    : ''
                                  }
                                </p>


                              </Hammer>

                            </div>
                          </Hammer>
                          <div class="like-share-section " >
                            <button class="detailstxt-artical-icon ml-3" onClick={handlePrevArticle} style={{ float: 'left', marginRight: "2px" }}>
                              <i class="fa-solid fa-chevron-left"></i></button>

                            <div class="article_btm-btns">
                              <Link className='detailstxt-artical-icon' to={`/editResource/${selectedArticleId}`}>
                                <i class=" fa-solid fa-user-pen"></i>
                              </Link>
                              <button class="detailstxt-artical-icon">
                                {/* <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i>
                            </button>
                            <button class="detailstxt-artical-icon">
                              <i className="fa-solid fa-share" style={{ fontSize: '24px' }}></i> */}
                                <a
                                  className="share-button"
                                  onClick={() => {
                                    if (navigator.share) {
                                      navigator.share({
                                        title: "Know " + title,
                                        text: "Know " + title,
                                        url: `https://www.templeswiki.com/templeDetails/${temple.rid}?prevPage=${window.location.pathname}`
                                      })
                                        .then(() => console.warn("Share successful."))
                                        .catch((error) => console.error("Error sharing:", error));
                                    } else {
                                      // Fallback for browsers that do not support the Web Share API
                                      console.error("Web Share API not supported.");
                                    }
                                  }}
                                >
                                  <i title="Share" className="fa-solid fa-share-nodes" style={{ fontSize: '24px', marginRight: '112px' }}></i>
                                </a>

                                <a
                                  className="share-button"
                                  onClick={() => toggleFavorite(selectedArticleId, temple.title)}
                                >
                                  {favList.some(t => t === temple.rid) ? (
                                    <i className="fa-solid fa-heart"></i>
                                  ) : (
                                    <i className="fa-regular fa-heart"></i>
                                  )}
                                </a>

                                {/* <a
                                className="share-button"
                                onClick={() => toggleFavorite(temple, title)}
                            >
                                {favList.some(t => t === temple.rid) ? (
                                    <i className="fa-solid fa-heart" style={{fontSize: '24px'}}></i>
                                ) : (
                                    <i className="fa-regular fa-heart" style={{fontSize: '24px'}}></i>
                                )}
                            </a> */}
                                {/* <i className="fa-solid fa-heart" style={{ fontSize: '24px' }}></i> */}

                              </button>
                            </div>
                            {isLoading && (<Error />)}
                            <button class="detailstxt-artical-icon mr-3" onClick={handleNextArticle} style={{ float: 'right' }}>
                              <i class="fa-solid fa-chevron-right"></i></button>
                          </div>

                        </div>


                      </div>
                      <div class="popup-main-footer d-none">
                        <button className="button_move" onClick={handlePrevArticle} style={{ float: 'left', marginRight: "2px" }}>{t('label_previous')}</button>
                        <button className="button_move" onClick={handleNextArticle} style={{ float: 'right' }}>{t('label_next')}</button>
                      </div>

                    </div>
                  </Hammer>
                </div>
              </div>
            )}

            <FloatButton.BackTop icon={<img style={{ height: 30, width: 30, marginLeft: -5 }} src={temple} alt="temple" />} />
          </div>
          <div className="next-row">
            {bDetails && bDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={currentPage === 0 ? null : prevTemplesList}
                  disabled={currentPage === 0}
                >
                  {t("label_previous")}
                </a>
              </Col>
            ) : (
              ''
            )}
            {bDetails && bDetails.length > 0 ? (
              <Col>
                <a
                  className="button_move"
                  onClick={bDetails.length > 12 ? null : nextTemplesList}
                  disabled={bDetails.length < 12}
                >
                  {t("label_next")}
                </a>
              </Col>
            ) : (
              ''
            )}
          </div>
          <Footer />
        </div>
      </div>

    </>
  )
}


export default CommonResource;